import React from 'react';
import Select from 'react-select';

import BuildPlatform from './BuildPlatform';
import {
  leagues,
  teams,
  games,
  game_states,
  platforms,
  game_conditions_opts_pregame,
  game_conditions_opts_live,
  game_conditions_opts_postgame,
  game_conditions_stats,
} from './PistonsBuild_data';
import './PistonsBuild.scss';

export default class PistonsBuild extends React.Component {
  constructor(props) {
    super(props);

    this.team_names = {
      'Atlanta Hawks': 'atl',
      'Chicago Bulls': 'chi',
      'Cleveland Cavaliers': 'cle',
      'Gold State Warriors': 'gsw',
      'Los Angeles Lakers': 'lal',
      'Milwaukee Bucks': 'mil',
    };

    this.state = {
      league: 'NBA',
      team: 'Pistons',
      game: 'Atlanta Hawks',
      game_short: 'atl',
      game_state: 'Postgame',
      game_condition: 'Home Win',
      platform: 'Desktop (728x90 and 300x250)',
      game_conditions_opts: game_conditions_opts_postgame,
      condition: 'Home Win',
      home_pts: '107',
      away_pts: '98',
      home_to: '0',
      away_to: '0',
      home_fs: '48',
      away_fs: '45',
      home_3pt: '40',
      away_3pt: '53',
      home_ft: '63',
      away_ft: '77',
      home_rbs: '48',
      away_rbs: '34',
      quarter: 'Final',
      fontsize: '1',
      clock: '0:00',
      top_line: 'Pistons',
      bottom_line: 'Win',
    };

    this.logGame = this.logGame.bind(this);
    this.updateGameData = this.updateGameData.bind(this);
    this.logGameState = this.logGameState.bind(this);
    this.logGameCondition = this.logGameCondition.bind(this);
    this.logPlatform = this.logPlatform.bind(this);
  }

  updateGameData(newState) {
    game_conditions_stats
      .filter(state => {
        if (state.condition === newState) {
          return state;
        }
      })
      .map(state => {
        Object.keys(state).map(k => {
          this.setState({ [k]: state[k] });
        });
      });
  }

  logGameCondition(obj) {
    this.updateGameData(obj.value);
    this.setState({ game_condition: obj.value });
  }

  logPlatform(obj) {
    this.setState({ platform: obj.value });
  }

  logGame(obj) {
    this.setState({ game: obj.value });
    this.setState({ game_short: this.team_names[obj.value] });
  }

  /**
   * This glues the relationships between Game State and Game Conditions
   */
  logGameState(obj) {
    if (obj.value === 'Pregame') {
      this.updateGameData(game_conditions_opts_pregame[0].value);
      this.setState({ game_state: obj.value });
      this.setState({ game_conditions_opts: game_conditions_opts_pregame });
      this.setState({ game_condition: game_conditions_opts_pregame[0].value });
    } else if (obj.value === 'Live') {
      this.updateGameData(game_conditions_opts_live[0].value);
      this.setState({ game_state: obj.value });
      this.setState({ game_conditions_opts: game_conditions_opts_live });
      this.setState({ game_condition: game_conditions_opts_live[0].value });
    } else if (obj.value === 'Postgame') {
      this.updateGameData(game_conditions_opts_postgame[0].value);
      this.setState({ game_state: obj.value });
      this.setState({ game_conditions_opts: game_conditions_opts_postgame });
      this.setState({ game_condition: game_conditions_opts_postgame[0].value });
    }
  }

  render() {
    return (
      <div className=''>
        <div className='PistonsLive_All'>
          <div className='CampaignBuilder'>
            <ul className='CampaignBuilder-list'>
              <li className='CampaignBuilder-item CampaignBuilder-select'>
                <label className='CampaignBuilder-label'>League</label>
                <Select
                  name='PistonsBuild-league'
                  value={leagues.filter(
                    ({ value }) => value === this.state.league
                  )}
                  options={leagues}
                  isDisabled
                  onChange={this.logChange}
                />
              </li>

              <li className='CampaignBuilder-item CampaignBuilder-select'>
                <label className='CampaignBuilder-label'>Team</label>
                <Select
                  name='PistonsBuild-team'
                  value={teams.filter(({ value }) => value === this.state.team)}
                  options={teams}
                  isDisabled
                  onChange={this.logChange}
                />
              </li>

              <li className='CampaignBuilder-item CampaignBuilder-select'>
                <label className='CampaignBuilder-label'>Game</label>
                <Select
                  name='PistonsBuild-game'
                  value={games.filter(({ value }) => value === this.state.game)}
                  options={games}
                  onChange={this.logGame}
                  clearable={false}
                />
              </li>
            </ul>

            <ul className='CampaignBuilder-list'>
              <li className='CampaignBuilder-item CampaignBuilder-select'>
                <label className='CampaignBuilder-label'>Game State</label>
                <Select
                  name='PistonsBuild-game_state'
                  value={game_states.filter(
                    ({ value }) => value === this.state.game_state
                  )}
                  options={game_states}
                  onChange={this.logGameState}
                  clearable={false}
                />
              </li>

              <li className='CampaignBuilder-item CampaignBuilder-select'>
                <label className='CampaignBuilder-label'>Game Conditions</label>
                <Select
                  name='PistonsBuild-game_condition'
                  value={this.state.game_conditions_opts.filter(
                    ({ value }) => value === this.state.game_condition
                  )}
                  options={this.state.game_conditions_opts}
                  onChange={this.logGameCondition}
                  clearable={false}
                />
              </li>

              <li className='CampaignBuilder-item CampaignBuilder-select'>
                <label className='CampaignBuilder-label'>Platform</label>
                <Select
                  name='PistonsBuild-platform'
                  value={platforms.filter(
                    ({ value }) => value === this.state.platform
                  )}
                  options={platforms}
                  onChange={this.logPlatform}
                  clearable={false}
                />
              </li>
            </ul>
          </div>

          <BuildPlatform
            key='I am unique'
            game={this.state.game}
            game_short={this.state.game_short}
            game_state={this.state.game_state}
            game_condition={this.state.game_condition}
            platform={this.state.platform}
            condition={this.state.game_state}
            home_pts={this.state.home_pts}
            away_pts={this.state.away_pts}
            home_to={this.state.home_to}
            away_to={this.state.away_to}
            home_fs={this.state.home_fs}
            away_fs={this.state.away_fs}
            home_3pt={this.state.home_3pt}
            away_3pt={this.state.away_3pt}
            home_ft={this.state.home_ft}
            away_ft={this.state.away_ft}
            home_rbs={this.state.home_rbs}
            away_rbs={this.state.away_rbs}
            quarter={this.state.quarter}
            fontsize={this.state.fontsize}
            clock={this.state.clock}
            top_line={this.state.top_line}
            bottom_line={this.state.bottom_line}
          />
        </div>
      </div>
    );
  }
}
