import React from 'react';
import { Outlet } from 'react-router-dom';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import './App.scss';
import Header from './components/Header';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.scss';
import AdsContainer from './components/AdsContainer';
import IndexAd from './components/IndexAd';
import AdsLive from './components/AdsLive';
import AdsSize from './components/AdsSize';
import AdsBuild from './components/AdsBuild';
import AdsCountdown from './components/AdsCountdown';
import AdsHighlights from './components/AdsHighlights';
import AdsVideo from './components/AdsVideo';
import { createBrowserHistory } from 'history';

const App = () => {
  function handleUpdate() {
    logPageView();
    let { action } = this.state.location;

    if (action === 'PUSH') {
      window.scrollTo(0, 0);
    }
  }

  function logPageView() {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
  }

  return (
    <BrowserRouter history={createBrowserHistory()} onUpdate={handleUpdate}>
      <Routes>
        <Route
          path='/'
          element={
            <div>
              <Header />
              <Outlet />
            </div>
          }
        >
          <Route index element={<IndexAd />} />
          <Route element={<AdsContainer />}>
            <Route path=':adName/live' element={<AdsLive />} />
            <Route path=':adName/sizes' element={<AdsSize />} />
            <Route path=':adName/build' element={<AdsBuild />} />
            <Route path=':adName/highlights' element={<AdsHighlights />} />
            <Route path=':adName/countdown' element={<AdsCountdown />} />
            <Route path=':adName/video' element={<AdsVideo />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;

App.propTypes = {
  children: PropTypes.node,
};
