import React from 'react';
import './DSG_300x250.scss';

export default class DSG_300x250 extends React.Component {
  render() {
    return (
      <div className="DSG_300x250">
        <a id='container' href='#' className={`${this.props.team} ${this.props.product_short}`}>
          <div id="logo">
            <img src="https://fanserv-media.s3.amazonaws.com/client/xaxis/dsg_holiday/300x250/dist/img/logo.png" />
          </div>
          {/* SLIDE 1 */}
          <div id="slide1">
            <img src="https://fanserv-media.s3.amazonaws.com/client/xaxis/dsg_holiday/300x250/dist/img/slide1.png"/>
          </div>

          {/* SLIDE 2 */}
          <div id="slide2">
            <img src="https://fanserv-media.s3.amazonaws.com/client/xaxis/dsg_holiday/300x250/dist/img/slide2.png"/>
          </div>
          <div id="slide4">
            <span id="slide4-copy">
              {this.props.market} {this.props.team}<br />
              <span id="productTitle">{this.props.product_copy}</span>
            </span>
            <img id="slide4-jersey" src={`https://fanserv-media.s3.amazonaws.com/client/xaxis/dsg_holiday/300x250/${this.props.product_short}/${this.props.team_id}.jpg`} />
            <div id="bottom-copy">
              Get Game<br />
              Day Ready
            </div>
          </div>
        </a>
      </div>
    )
  }
}
