import React from 'react';

import CanucksLive_All from './ads/canucks/CanucksLive_All';
import PistonsLive_All from './ads/pistons/PistonsLive_All';
import SeatGeekLive_All from './ads/seatgeek/SeatGeekLive_All';
import DSGLive_All from './ads/dsg/DSGLive_All';
import PGALive_All from './ads/pga/PGA_Live';
import CampaignAlpha from './CampaignAlpha';
import { useParams } from 'react-router';

const AdsLive = () => {
  const { adName } = useParams();
  let ads = null;
  if (adName === 'pistons') {
    ads = <PistonsLive_All key={'PistonsLive_All'} />;
  } else if (adName === 'canucks') {
    ads = <CanucksLive_All key={'CanucksLive_All'} />;
  } else if (adName === 'seatgeek') {
    ads = <SeatGeekLive_All key={'SeatGeekLive_All'} />;
  } else if (adName === 'dsg') {
    ads = <DSGLive_All key={'DSGLive_All'} />;
  } else if (adName === 'pga') {
    ads = <PGALive_All key={'PGALive'} />;
  } else if (adName === 'wild') {
    ads = <CampaignAlpha key={'CampaignAlpha'} />;
  }

  return <div className='AdsWrapper-300x250'>{ads}</div>;
};

export default AdsLive;
