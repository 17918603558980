import React from 'react';

import PistonsSize_728x90 from './PistonsSize_728x90';
import PistonsLive_1 from './PistonsLive_1';
import './PistonsSize_All.scss' ;

export default class PistonsSize_All extends React.Component {
  render() {
    return (
      <div className="PistonsSize_All">
        <PistonsSize_728x90 />
        <PistonsLive_1
          key="5"
          home="lal"
          away="det"
          home_pts="50"
          away_pts="51"
          home_to="3"
          away_to="4"
          home_fs="62"
          away_fs="72"
          home_3pt="100"
          away_3pt="66"
          home_ft="78.4"
          away_ft="80.2"
          home_rbs="51"
          away_rbs="55"
          quarter="Q2"
          fontsize="3.5"
          clock="12:09"
          top_line="1-Point"
          bottom_line="Game"
        />
      </div>
    )
  }
}
