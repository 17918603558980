import React from 'react';
import './PistonsBuild_728x90.scss' ;

export default class PistonsSize_728x90 extends React.Component {
  render() {
    let gameState = this.props.condition;
    let halfTimeException = this.props.quarter;

    let livePill = null;
    if (gameState === 'Live') {
      livePill = <div className="live">Live</div>
    }

    let toAway = null;
    if (gameState === 'Live') {
      toAway = (
        <div className="fouls-meter fouls-away">
          <span className="to-abrev">TO</span> {this.props.away_to}
        </div>
      )
    }

    let toHome = null;
    if (gameState === 'Live') {
      toHome = (
        <div className="fouls-meter fouls-home">
          <span className="to-abrev">TO</span> {this.props.home_to}
        </div>
      )
    }

    let clockQuarter = null;
    if (gameState === 'Pregame') {
      clockQuarter = (
        <div className="scoreboard-clock">
          <div className="clock-wrapper">
            <div className="live-pregame">Start Time:</div>
            <div className="scoreboard-qt">49 Min.</div>
            <div className="scoreboard-time"></div>
          </div>
        </div>
      )
    } else if (halfTimeException === 'Half') {
      clockQuarter = (
        <div className="scoreboard-clock">
          <div className="clock-wrapper">
            <div className="scoreboard-qt">Halftime</div>
          </div>
        </div>
      )
    } else if (gameState === 'Live') {
      clockQuarter = (
        <div className="scoreboard-clock">
          <div className="clock-wrapper">
            <div className="scoreboard-qt">Q{this.props.quarter}</div>
            <div className="scoreboard-time">{this.props.clock}</div>
          </div>
        </div>
      )
    } else if (gameState === 'Postgame') {
      clockQuarter = (
        <div className="scoreboard-clock">
          <div className="clock-wrapper">
            <div className="scoreboard-qt">FINAL</div>
            <div className="scoreboard-time"></div>
          </div>
        </div>
      )
    }

    return (
      <div className="PistonsSize_728x90">
        <a href="#" className="ad-container loaded">
          <div className="fox-logo"></div>
          <div className="game-copy">
            <div className="copy-double line1">{this.props.top_line}</div>
            <div className="copy-double line2">{this.props.bottom_line}</div>
          </div>
          <div className="button-scoreboard">
            <div className="scoreboard-wrapper">
              <div className="scoreboard-frame scoreboard-two">
                <table>
                  <thead>
                    <tr>
                      <td></td>
                      <td>PTS</td>
                      <td>FG%</td>
                      <td>3 PT%</td>
                      <td>FT %</td>
                      <td>RBS</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="table-team">{this.props.away}</td>
                      <td>{this.props.away_pts}</td>
                      <td>{this.props.away_fs}</td>
                      <td>{this.props.away_3pt}</td>
                      <td>{this.props.away_ft}</td>
                      <td>{this.props.away_rbs}</td>
                    </tr>
                    <tr>
                      <td className="table-team">DET</td>
                      <td>{this.props.home_pts}</td>
                      <td>{this.props.home_fs}</td>
                      <td>{this.props.home_3pt}</td>
                      <td>{this.props.home_ft}</td>
                      <td>{this.props.home_rbs}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            <div className="scoreboard-frame scoreboard-one">
              <div className="team-box team-away">
                <div>
                  <img src={`https://fanserv-media.s3.amazonaws.com/client/fox_sports/pistons-tune-in-v2/300x250/dist/images/logo-${this.props.away}.png`} width="75" height="46" className="team-logo" />
                </div>
              </div>
              <div className="score-box score-away">
                <div className="points points-away">{this.props.away_pts}</div>
                {toAway}
              </div>
              {clockQuarter}
              <div className="score-box score-home">
                <div className="points points-home">{this.props.home_pts}</div>
                {toHome}
              </div>
              <div className="team-box team-home">
                <div>
                  <img src={`https://fanserv-media.s3.amazonaws.com/client/fox_sports/pistons-tune-in-v2/300x250/dist/images/logo-${this.props.home}.png`} width="75" height="46" className="team-logo" />
                </div>
              </div>
            </div>
          </div>
        <div className="tune-in-button">
          <span className="detBlue">Ch. 737</span> <span className="tune-in-icon icon-apple"></span> <span className="tune-in-icon icon-android"></span> TUNE IN NOW!
        </div>
      </div>
          {livePill}
          <div className="billboard-content">
            <div className="billboard-player">
              <img src="https://fanserv-media.s3.amazonaws.com/client/fox_sports/pistons-tune-in-v2/728x90/dist/images/player.png" className="player-img" />
            </div>
          </div>
        </a>
      </div>
    )
  }
}
