import React from 'react';

import CanucksLive_1 from './CanucksLive_1';
import CanucksSize_300x600 from './CanucksSize_300x600';
import CanucksSize_728x90 from './CanucksSize_728x90';
import CanucksSize_160x600 from './CanucksSize_160x600';
// import CanucksLive_2 from './CanucksLive_2';
// import CanucksLive_3 from './CanucksLive_3';
// import CanucksLive_4 from './CanucksLive_4';
import './CanucksSize_All.scss' ;

export default class CanucksSize_All extends React.Component {
  render() {
    return (
      <div className="CanucksSize_All">
        <CanucksSize_728x90 key="1" />
        <CanucksSize_300x600 key="2" />
        <CanucksLive_1 key="3" />
        <CanucksSize_160x600 key="4" />
      </div>
    )
  }
}
