import React from 'react';

import SeatGeekSize_300x250 from './SeatGeekSize_300x250';
import './SeatGeekLive_All.scss' ;

export default class SeatGeekLive_All extends React.Component {
  render() {
    return (
      <div className="SeatGeekLive_All">
        <SeatGeekSize_300x250 key={1}
          home={'braves'}
          away={'padres'}
          home_short={'atl'}
          away_short={'sd'}
          id_home={1147}
          id_away={1160}
        />
        <SeatGeekSize_300x250 key={2}
          home={'reds'}
          away={'nationals'}
          home_short={'cin'}
          away_short={'was'}
          id_home={1142}
          id_away={1169}
        />
        <SeatGeekSize_300x250 key={3}
          home={'yankees'}
          away={'houston'}
          home_short={'ana'}
          away_short={'hou'}
          id_home={1142}
          id_away={1153}
        />
        <SeatGeekSize_300x250 key={4}
          home={'dodgers'}
          away={'orioles'}
          home_short={'la'}
          away_short={'bal'}
          id_home={1156}
          id_away={1173}
        />
        <SeatGeekSize_300x250 key={5}
          home={'brewers'}
          away={'royals'}
          home_short={'mil'}
          away_short={'kan'}
          id_home={1158}
          id_away={1165}
        />
        <SeatGeekSize_300x250 key={6}
          home={'twins'}
          away={'cubs'}
          home_short={'min'}
          away_short={'chi'}
          id_home={1149}
          id_away={1144}
        />
        <SeatGeekSize_300x250 key={7}
          home={'tigers'}
          away={'athletics'}
          home_short={'det'}
          away_short={'oak'}
          id_home={1164}
          id_away={1152}
        />
        <SeatGeekSize_300x250 key={8}
          home={'rangers'}
          away={'pirates'}
          home_short={'tex'}
          away_short={'pit'}
          id_home={1143}
          id_away={1145}
        />
        <SeatGeekSize_300x250 key={9}
          home={'indians'}
          away={'cubs'}
          home_short={'cle'}
          away_short={'chi'}
          id_home={1162}
          id_away={1144}
        />
      </div>
    )
  }
}
