import React from 'react';
import './PGA_Ad_Highlights.scss';

export default class PGA_Ad_Highlights extends React.Component {
  render() {
    return (
      <div className="PGA_Ad_Highlights">
        <a href="#" target="_blank" className="container ad-size-728x90">
          <div className="videoContainer">
            <video autoPlay loop muted playsInline>
              <source src="/videos/fedex.mp4" type="video/mp4"/>
              <source src="/videos/fedex.ogv" type="video/ogg"/>
              <source src="/videos/fedex.webm" type="video/webm"/>
            </video>
          </div>

          <div className="leaderBoard">
          </div>
        </a>
      </div>
    )
  }
}
