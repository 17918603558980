import React from 'react';
import './CanucksSize_728x90.scss';

export default class CanucksSize_728x90 extends React.Component {
  render() {
    return (
      <div className="CanucksSize_728x90">
        <a id="ad-container" href="#" target="_blank" className="container ad-size-728x90 loaded">
            <div className="adContent">
              <div className="copy-content">
                <div className="game-info">
                  <span className="team-top">Canucks <span className="vs">VS</span></span><br/>
                  <span className="team-bottom">Panthers</span><br/>
                  <span className="date">Fri.Jan.20</span>
                </div>
                <div className="ticket-info">
                  <div className="ticket-price">
                    <span className="spinner hide">
                      <img src="https://fanserv-media.s3.amazonaws.com/client/canucks/728x90/game_4/dist/images/shared/spinner.svg"/>
                    </span>
                    <span className="ticket-from">
                      <span className="ticket-label">Tickets From</span><br/>
                      <span className="dollar-sign">$</span>
                      <span className="dollar-price">68</span>
                    </span>
                    <span className="available-msg hide">
                      Limited Tickets Available
                    </span>
                  </div>
                </div>
              </div>
              <div className="type-texture"></div>
              <div className="cta-button">Find Tickets</div>
              <div className="motion-content">
                <div className="player-frame player-background"></div>
                <div className="player-frame player-foreground"></div>
                <div className="texture"></div>
              </div>
            </div>
          </a>
      </div>
    )
  }
}
