export const leagues = [
  { value: 'NBA', label: 'NBA' }
]

export const teams = [
  { value: 'Pistons', label: 'Pistons' }
]

export const games = [
  { value: 'Atlanta Hawks', label: 'Atlanta Hawks' },
  { value: 'Chicago Bulls', label: 'Chicago Bulls' },
  { value: 'Cleveland Cavaliers', label: 'Cleveland Cavaliers' },
  { value: 'Gold State Warriors', label: 'Gold State Warriors' },
  { value: 'Los Angeles Lakers', label: 'Los Angeles Lakers' },
  { value: 'Milwaukee Bucks', label: 'Milwaukee Bucks' }
]

export const game_states = [
  { value: 'Pregame', label: 'Pregame' },
  { value: 'Live', label: 'Live' },
  { value: 'Postgame', label: 'Postgame' }
]

export const game_conditions = [
  { value: 'Countdown', label: 'Countdown' },
  { value: 'Pistons Up 10', label: 'Pistons Up 10' },
  { value: 'Pistons Down 2', label: 'Pistons Down 2' },
  { value: 'Halftime', label: 'Halftime' },
  { value: 'Tight Game', label: 'Tight Game' },
  { value: '1 Point Game in 4th Qtr', label: '1 Point Game in 4th Qtr' },
  { value: 'Home Win', label: 'Home Win' },
  { value: 'Home Loss', label: 'Home Loss' }
]

export const platforms = [
  { value: 'Desktop (728x90 and 300x250)', label: 'Desktop (728x90 and 300x250)' },
  { value: 'Mobile Web (300x250)', label: 'Mobile Web (300x250)' },
  { value: 'Tablet (728x90)', label: 'Tablet (728x90)' }
]

export const game_conditions_opts_pregame = [
  { value: 'Countdown', label: 'Countdown' }
]

export const game_conditions_opts_live = [
  { value: 'Pistons Up 10', label: 'Pistons Up 10' },
  { value: 'Pistons Down 2', label: 'Pistons Down 2' },
  { value: 'Halftime', label: 'Halftime' },
  { value: 'Tight Game', label: 'Tight Game' },
  { value: '1 Point Game in 4th Qtr', label: '1 Point Game labeln 4th Qtr' }
]

export const game_conditions_opts_postgame = [
  { value: 'Home Win', label: 'Home Win' },
  { value: 'Home Loss', label: 'Home Loss' }
]

export const game_conditions_stats = [
  {
    condition: 'Countdown',
    home_pts: '0',
    away_pts: '0',
    home_to: '6',
    away_to: '6',
    home_fs: '0',
    away_fs: '0',
    home_3pt: '0',
    away_3pt: '0',
    home_ft: '0',
    away_ft: '0',
    home_rbs: '0',
    away_rbs: '0',
    quarter: '1',
    fontsize: '1',
    clock: '12:00',
    top_line: 'Almost',
    bottom_line: 'Tip Off'
  },
  {
    condition: 'Halftime',
    home_pts: '58',
    away_pts: '58',
    home_to: '0',
    away_to: '0',
    home_fs: '43',
    away_fs: '40',
    home_3pt: '35',
    away_3pt: '38',
    home_ft: '84',
    away_ft: '88',
    home_rbs: '25',
    away_rbs: '23',
    quarter: 'Half',
    fontsize: '1',
    clock: '0:00',
    top_line: 'Tied',
    bottom_line: 'At Half'
  },
  {
    condition: 'Tight Game',
    home_pts: '70',
    away_pts: '67',
    home_to: '5',
    away_to: '4',
    home_fs: '49',
    away_fs: '42',
    home_3pt: '39',
    away_3pt: '30',
    home_ft: '72',
    away_ft: '78',
    home_rbs: '35',
    away_rbs: '32',
    quarter: '3',
    fontsize: '1',
    clock: '9:32',
    top_line: 'Tight',
    bottom_line: 'Game'
  },
  {
    condition: 'Home Win',
    home_pts: '107',
    away_pts: '98',
    home_to: '0',
    away_to: '0',
    home_fs: '48',
    away_fs: '45',
    home_3pt: '40',
    away_3pt: '53',
    home_ft: '63',
    away_ft: '77',
    home_rbs: '48',
    away_rbs: '34',
    quarter: 'Final',
    fontsize: '1',
    clock: '0:00',
    top_line: 'Pistons',
    bottom_line: 'Win'
  },
  {
    condition: 'Home Loss',
    home_pts: '104',
    away_pts: '109',
    home_to: '0',
    away_to: '0',
    home_fs: '50',
    away_fs: '48',
    home_3pt: '35',
    away_3pt: '42',
    home_ft: '70',
    away_ft: '76',
    home_rbs: '38',
    away_rbs: '43',
    quarter: 'Final',
    fontsize: '1',
    clock: '0:00',
    top_line: 'Detroit',
    bottom_line: 'Falls'
  },
  {
    condition: '1 Point Game in 4th Qtr',
    home_pts: '113',
    away_pts: '112',
    home_to: '1',
    away_to: '0',
    home_fs: '55',
    away_fs: '43',
    home_3pt: '45',
    away_3pt: '35',
    home_ft: '76',
    away_ft: '95',
    home_rbs: '49',
    away_rbs: '33',
    quarter: '4',
    fontsize: '1',
    clock: '0:22',
    top_line: '1Pt Game',
    bottom_line: 'In 4th'
  },
  {
    condition: 'Pistons Up 10',
    home_pts: '26',
    away_pts: '16',
    home_to: '3',
    away_to: '4',
    home_fs: '49',
    away_fs: '38',
    home_3pt: '40',
    away_3pt: '24',
    home_ft: '64',
    away_ft: '78',
    home_rbs: '15',
    away_rbs: '7',
    quarter: '1',
    fontsize: '1',
    clock: '1:53',
    top_line: 'Pistons',
    bottom_line: 'Up 10'
  },
  {
    condition: 'Pistons Down 2',
    home_pts: '18',
    away_pts: '20',
    home_to: '3',
    away_to: '2',
    home_fs: '50',
    away_fs: '49',
    home_3pt: '36',
    away_3pt: '43',
    home_ft: '71',
    away_ft: '77',
    home_rbs: '12',
    away_rbs: '11',
    quarter: '1',
    fontsize: '1',
    clock: '3:14',
    top_line: 'Pistons',
    bottom_line: 'Down 2'
  }
]
