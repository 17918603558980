import React from 'react';
import './PGA_Ad_Live.scss';

export default class PGA_Ad_Live extends React.Component {
  constructor() {
    super();
    this.state = {
      loaded: ''
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loaded: 'loaded' })
    }, 300)
  }

  render() {
    let bigClass = `PGA_Ad_Live ${this.state.loaded}`

    return (
      <div className={bigClass}>
        <a href="#" className="ad_container-300x250">
          <ul className="leaders">
            <li className="player">
              <div className="player--avatar">
                <img src="https://fanserv-media.s3.amazonaws.com/sales_tool/pga_headshots/1.png" />
              </div>
              <div className="player--rank">
                1
              </div>
              <div className="player--name">Russell Henley</div>
            </li>
            <li className="player">
              <div className="player--avatar">
                <img src="https://fanserv-media.s3.amazonaws.com/sales_tool/pga_headshots/2.png" />
              </div>
              <div className="player--rank">
                2
              </div>
              <div className="player--name">Sung Kang</div>
            </li>
            <li className="player">
              <div className="player--avatar">
                <img src="https://fanserv-media.s3.amazonaws.com/sales_tool/pga_headshots/3.png" />
              </div>
              <div className="player--rank">
                3
              </div>
              <div className="player--name">Luke List</div>
            </li>
            <li className="player">
              <div className="player--avatar">
                <img src="https://fanserv-media.s3.amazonaws.com/sales_tool/pga_headshots/4.png" />
              </div>
              <div className="player--rank">
                4
              </div>
              <div className="player--name">Rickie Fowler</div>
            </li>
            <li className="player">
              <div className="player--avatar">
                <img src="https://fanserv-media.s3.amazonaws.com/sales_tool/pga_headshots/5.png" />
              </div>
              <div className="player--rank">
                5
              </div>
              <div className="player--name">Daniel Berger</div>
            </li>
          </ul>
        </a>
      </div>
    )
  }
}
