import React from 'react';
import { useParams } from 'react-router';

import PGA_highlights from './ads/pga/PGA_highlights';

const AdsLive = () => {
  const { adName } = useParams();

  let ads = null;
  if (adName === 'pga') {
    ads = <PGA_highlights key={'PGA_scoreboard'} />;
  }

  return <div className='AdsWrapper-300x250'>{ads}</div>;
};

export default AdsLive;
