import React from 'react';
import { Link } from 'react-router-dom';
// import Ad_Canucks from './ads/Ad_Canucks';
import './IndexAd.scss';

import { ads } from '../data';

class IndexAd extends React.Component {
  render() {
    const buildAd = (ad, i) => {
      let btn = null;
      if (ad.short_name === 'pistons') {
        btn = (
          <div>
            <Link
              to={`/${ad.short_name}/build`}
              className='IndexAd-btn hideMobile'
            >
              {ad.button_title}
            </Link>
            <Link
              to={`/${ad.short_name}/live`}
              className='IndexAd-btn showMobile'
            >
              {ad.button_title}
            </Link>
          </div>
        );
      } else if (ad.short_name === 'pepsi' || ad.short_name === 'cowboys') {
        btn = (
          <div>
            <Link to={`/${ad.short_name}/video`} className='IndexAd-btn'>
              {ad.button_title}
            </Link>
          </div>
        );
      } else {
        btn = (
          <Link to={`/${ad.short_name}/live`} className='IndexAd-btn'>
            {ad.button_title}
          </Link>
        );
      }

      return (
        <article
          key={i}
          className={`IndexAd-wrapper IndexAd-bg-${ad.short_name}`}
        >
          <Link className='IndexAd-logoWrapper' to={`/${ad.short_name}/live`}>
            <img
              src={`${process.env.PUBLIC_URL}/images/${ad.short_name}_logo.svg`}
              alt={`${ad.name} logo`}
              className='IndexAd-logo'
            />
          </Link>

          <p className='IndexAd-desc'>{ad.desc}</p>

          <div id='bg'>{btn}</div>
        </article>
      );
    };
    return <div>{ads.map(buildAd)}</div>;
  }
}

export default IndexAd;
