import React from 'react';
import PropTypes from 'prop-types';

const MLBBoxScore = props => (
  <div className='boxScore'>
    <table>
      <thead>
        <tr>
          <th colSpan='2'></th>
          <th>1</th>
          <th>2</th>
          <th>3</th>
          <th>4</th>
          <th>5</th>
          <th>6</th>
          <th>7</th>
          <th>8</th>
          <th>9</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className='teamAbr' colSpan='2'>
            {props.away_short}
          </td>
          <td>{props.away_1}</td>
          <td>{props.away_2}</td>
          <td>{props.away_3}</td>
          <td>{props.away_4}</td>
          <td>{props.away_5}</td>
          <td>{props.away_6}</td>
          <td>{props.away_7}</td>
          <td>{props.away_8}</td>
          <td>{props.away_9}</td>
        </tr>
        <tr>
          <td className='teamAbr' colSpan='2'>
            {props.home_short}
          </td>
          <td>{props.home_1}</td>
          <td>{props.home_2}</td>
          <td>{props.home_3}</td>
          <td>{props.home_4}</td>
          <td>{props.home_5}</td>
          <td>{props.home_6}</td>
          <td>{props.home_7}</td>
          <td>{props.home_8}</td>
          <td>{props.home_9}</td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default MLBBoxScore;

MLBBoxScore.propTypes = {
  away_short: PropTypes.string,
  home_short: PropTypes.string,
  away_1: PropTypes.string,
  away_2: PropTypes.string,
  away_3: PropTypes.string,
  away_4: PropTypes.string,
  away_5: PropTypes.string,
  away_6: PropTypes.string,
  away_7: PropTypes.string,
  away_8: PropTypes.string,
  away_9: PropTypes.string,
  home_1: PropTypes.string,
  home_2: PropTypes.string,
  home_3: PropTypes.string,
  home_4: PropTypes.string,
  home_5: PropTypes.string,
  home_6: PropTypes.string,
  home_7: PropTypes.string,
  home_8: PropTypes.string,
  home_9: PropTypes.string,
};
