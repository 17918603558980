import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import './CampaignAlpha.scss';
import Select from 'react-select';
import CampaignAlpha_300x250 from './CampaignAlpha_300x250';

import {
  leagues,
  teams,
  games,
  game_states,
  campaigns,
  game_conditions,
  game_stats,
  platforms,
} from './CampaignAlpha_data';

export default class CampaignAlpha extends Component {
  constructor() {
    super();

    this.team_names = {
      'Anaheim Ducks': 'ducks',
      'Boston Bruins': 'bruins',
      'Buffalo Sabres': 'sabres',
      'Calgary Flames': 'flames',
      'Carolina Hurricanes': 'hurricanes',
      'Chicago Blackhawks': 'blackhawks',
      'Colorado Avalanche': 'avalanche',
      'Columbus Blue Jackets': 'blue_jackets',
      'Dallas Stars': 'stars',
      'Detroit Red Wings': 'red_wings',
      'Edmonton Oilers': 'oilers',
      'Florida Panthers': 'panthers',
      'Los Angeles Kings': 'kings',
      'Minnesota Wild': 'wild',
      'Montreal Canadiens': 'canadiens',
      'Nashville Predators': 'predators',
      'New Jersey Devils': 'devils',
      'New York Islanders': 'islanders',
      'New York Rangers': 'rangers',
      'Ottawa Senators': 'senators',
      'Philadelphia Flyers': 'flyers',
      'Phoenix Coyotes': 'coyotes',
      'Pittsburgh Penguins': 'penguins',
      'Saint Louis Blues': 'blues',
      'San Jose Sharks': 'sharks',
      'Tampa Bay Lighting': 'lightning',
      'Toronto Maple Leafs': 'maple_leafs',
      'Vancouver Canucks': 'canucks',
      'Washington Capitals': 'capitals',
      'Winnipeg Jets': 'jets',
    };

    this.state = {
      platform: null,
      campaign: null,
      game: null,
      game_state: null,
      game_condition: null,
      home_team: 'Minnesota Wild',
      home_team_short: 'wild',
      away_team: null,
      away_team_short: null,
      home_goals: null,
      aways_goals: null,
      clock: null,
      period: null,
    };

    this.logGame = this.logGame.bind(this);
    this.logCampaign = this.logCampaign.bind(this);
    this.logGameState = this.logGameState.bind(this);
    this.logGameCondition = this.logGameCondition.bind(this);
    this.updateGameData = this.updateGameData.bind(this);
    this.logPlatform = this.logPlatform.bind(this);
  }

  updateGameData(newState) {
    let stateObj =
      game_stats[this.state.campaign][this.state.game_state][newState];
    Object.keys(stateObj).map(k => {
      this.setState({ [`${k}`]: stateObj[k] });
    });
  }

  resetGameCondition() {
    this.setState({ home_goals: null });
    this.setState({ aways_goals: null });
    this.setState({ clock: null });
    this.setState({ period: null });
  }

  /**
   * Select Menu Functions
   */
  logCampaign(obj) {
    this.setState({ campaign: obj.value });
    this.setState({ game: null });
    this.setState({ away_team: null });
    this.setState({ away_team: null });
    this.resetGameCondition();
  }

  logGameState(obj) {
    this.setState({ game_state: obj.value });
    this.resetGameCondition();
  }

  logGame(obj) {
    this.setState({ game: obj.value });
    this.setState({ away_team: obj.value });
    this.setState({ away_team_short: this.team_names[obj.value] });
  }

  logGameCondition(obj) {
    this.setState({ game_condition: obj.value });
    this.updateGameData(obj.value);
  }

  logPlatform(obj) {
    this.setState({ platform: obj.value });
    // this.resetGameCondition()
  }

  render() {
    let games_dd = null;
    if (this.state.campaign === 'ReplyBuy') {
      games_dd = [
        { value: 'Anaheim Ducks', label: 'Anaheim Ducks' },
        { value: 'Detroit Red Wings', label: 'Detroit Red Wings' },
      ];
    } else {
      games_dd = games;
    }

    let gc_campaign = this.state.campaign;
    let gc_game_state = this.state.game_state;
    let gc_dd = null;
    if (this.state.campaign === 'ReplyBuy') {
      gc_dd = game_conditions[gc_campaign][gc_game_state];
    } else if (this.state.campaign === "Wendy's") {
      gc_dd = game_conditions[gc_campaign][gc_game_state];
    } else if (this.state.campaign === 'Coca-Cola') {
      gc_dd = game_conditions[gc_campaign][gc_game_state];
    }

    return (
      <div>
        <div className='CAWrapper'>
          <div className='CASelectionInterface'>
            <h2 className='CASelectionTitle'>Campaign Builder</h2>
            <div className='CASelectionInterface-selection'>
              {/* <label className="CASelectionLabel">Platform</label>
              <Select
                name="CampaignAlpha-platform"
                value={this.state.platform}
                options={platforms}
                onChange={this.logPlatform}
                clearable={false}
              /> */}
            </div>
            <div className='CASelectionInterface-selection'>
              <label className='CASelectionLabel'>Campaign</label>
              <Select
                name='CampaignAlpha-campaign'
                options={campaigns}
                onChange={this.logCampaign}
                clearable={false}
              />
            </div>
            <div className='CASelectionInterface-selection'>
              <label className='CASelectionLabel'>League</label>
              <Select
                name='CampaignAlpha-league'
                value={{ value: 'NHL', label: 'NHL' }}
                options={leagues}
                isDisabled
                onChange={this.logChange}
                clearable={false}
              />
            </div>
            <div className='CASelectionInterface-selection'>
              <label className='CASelectionLabel'>Team</label>
              <Select
                name='CampaignAlpha-team'
                value={{ value: 'Minnesota Wild', label: 'Minnesota Wild' }}
                options={teams}
                isDisabled
                onChange={this.logTeam}
                clearable={false}
              />
            </div>
            <div className='CASelectionInterface-selection'>
              <label className='CASelectionLabel'>Game</label>
              <Select
                name='CampaignAlpha-game'
                options={games_dd}
                onChange={this.logGame}
                clearable={false}
              />
            </div>
            <div className='CASelectionInterface-selection'>
              <label className='CASelectionLabel'>Game State</label>
              <Select
                name='PistonsBuild-game'
                options={game_states}
                onChange={this.logGameState}
                clearable={false}
              />
            </div>
            <div className='CASelectionInterface-selection'>
              <label className='CASelectionLabel'>Game Conditions</label>
              <Select
                name='PistonsBuild-game'
                options={gc_dd}
                onChange={this.logGameCondition}
                clearable={false}
              />
            </div>
          </div>

          <div className='CAStageWrapper'>
            <div className='CAStage'>
              <div className='CAStage-300x250-container'>
                <CampaignAlpha_300x250
                  campaign={this.state.campaign}
                  game={this.state.game}
                  game_state={this.state.game_state}
                  game_condition={this.state.game_condition}
                  home_team={this.state.home_team}
                  home_team_short={this.state.home_team_short}
                  away_team={this.state.away_team}
                  away_team_short={this.state.away_team_short}
                  home_goals={this.state.home_goals}
                  aways_goals={this.state.aways_goals}
                  clock={this.state.clock}
                  period={this.state.period}
                />
              </div>
              <Isvg src='/mobile.svg' />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
