import React from 'react';
import PistonsBuild_300x250 from './PistonsBuild_300x250';
import PistonsBuild_728x90 from './PistonsBuild_728x90';
import Isvg from 'react-inlinesvg';
import './BuildPlatform.scss';
import PropTypes from 'prop-types';

export default class BuildPlatform extends React.Component {
  constructor() {
    super();
  }

  render() {
    const default_platform = this.props.platform;

    let platformVersion = null;
    if (default_platform === 'Desktop (728x90 and 300x250)') {
      platformVersion = (
        <div className='BuildPlatform' key={'desktop'}>
          <div className='adsContainer'>
            <div className='adContainer_728x90 desktop'>
              <PistonsBuild_728x90
                key='PistonsBuild_728x90_desktop'
                home='det'
                away={this.props.game_short}
                condition={this.props.condition}
                home_pts={this.props.home_pts}
                away_pts={this.props.away_pts}
                home_to={this.props.home_to}
                away_to={this.props.away_to}
                home_fs={this.props.home_fs}
                away_fs={this.props.away_fs}
                home_3pt={this.props.home_3pt}
                away_3pt={this.props.away_3pt}
                home_ft={this.props.home_ft}
                away_ft={this.props.away_ft}
                home_rbs={this.props.home_rbs}
                away_rbs={this.props.away_rbs}
                quarter={this.props.quarter}
                fontsize={this.props.fontsize}
                clock={this.props.clock}
                top_line={this.props.top_line}
                bottom_line={this.props.bottom_line}
              />
            </div>

            <div className='adContainer_300x250 desktop'>
              <PistonsBuild_300x250
                key='PistonsBuild_300x250_desktop'
                home='det'
                away={this.props.game_short}
                condition={this.props.condition}
                home_pts={this.props.home_pts}
                away_pts={this.props.away_pts}
                home_to={this.props.home_to}
                away_to={this.props.away_to}
                home_fs={this.props.home_fs}
                away_fs={this.props.away_fs}
                home_3pt={this.props.home_3pt}
                away_3pt={this.props.away_3pt}
                home_ft={this.props.home_ft}
                away_ft={this.props.away_ft}
                home_rbs={this.props.home_rbs}
                away_rbs={this.props.away_rbs}
                quarter={this.props.quarter}
                fontsize={this.props.fontsize}
                clock={this.props.clock}
                top_line={this.props.top_line}
                bottom_line={this.props.bottom_line}
              />
            </div>
          </div>
          <div className='platformContainer'>
            <Isvg src='/browser.svg' />
          </div>
        </div>
      );
    } else if (default_platform === 'Mobile Web (300x250)') {
      platformVersion = (
        <div className='BuildPlatform' key='mobilie'>
          <div className='adsContainer'>
            <div className='adContainer_300x250 mobile'>
              <PistonsBuild_300x250
                key='PistonsBuild_300x250_mobile'
                home='det'
                away={this.props.game_short}
                condition={this.props.condition}
                home_pts={this.props.home_pts}
                away_pts={this.props.away_pts}
                home_to={this.props.home_to}
                away_to={this.props.away_to}
                home_fs={this.props.home_fs}
                away_fs={this.props.away_fs}
                home_3pt={this.props.home_3pt}
                away_3pt={this.props.away_3pt}
                home_ft={this.props.home_ft}
                away_ft={this.props.away_ft}
                home_rbs={this.props.home_rbs}
                away_rbs={this.props.away_rbs}
                quarter={this.props.quarter}
                fontsize={this.props.fontsize}
                clock={this.props.clock}
                top_line={this.props.top_line}
                bottom_line={this.props.bottom_line}
              />
            </div>
          </div>
          <div className='platformContainer'>
            <Isvg src='/mobile.svg' />
          </div>
        </div>
      );
    } else if (default_platform === 'Tablet (728x90)') {
      platformVersion = (
        <div className='BuildPlatform' key='tablet'>
          <div className='adsContainer'>
            <div className='adContainer_728x90 platform'>
              <PistonsBuild_728x90
                key='PistonsBuild_728x90_tablet'
                home='det'
                away={this.props.game_short}
                condition={this.props.condition}
                home_pts={this.props.home_pts}
                away_pts={this.props.away_pts}
                home_to={this.props.home_to}
                away_to={this.props.away_to}
                home_fs={this.props.home_fs}
                away_fs={this.props.away_fs}
                home_3pt={this.props.home_3pt}
                away_3pt={this.props.away_3pt}
                home_ft={this.props.home_ft}
                away_ft={this.props.away_ft}
                home_rbs={this.props.home_rbs}
                away_rbs={this.props.away_rbs}
                quarter={this.props.quarter}
                fontsize={this.props.fontsize}
                clock={this.props.clock}
                top_line={this.props.top_line}
                bottom_line={this.props.bottom_line}
              />
            </div>
          </div>
          <div className='platformContainer'>
            <Isvg src='/tablet.svg' />
          </div>
        </div>
      );
    }
    return <div>{platformVersion}</div>;
  }
}

BuildPlatform.propTypes = {
  game: PropTypes.string,
  game_short: PropTypes.string,
  game_state: PropTypes.string,
  game_condition: PropTypes.string,
  platform: PropTypes.string,
};

BuildPlatform.propTypes = {
  game_short: PropTypes.string,
  condition: PropTypes.string,
  home_pts: PropTypes.string,
  away_pts: PropTypes.string,
  home_to: PropTypes.string,
  away_to: PropTypes.string,
  home_fs: PropTypes.string,
  away_fs: PropTypes.string,
  home_3pt: PropTypes.string,
  away_3pt: PropTypes.string,
  home_ft: PropTypes.string,
  away_ft: PropTypes.string,
  home_rbs: PropTypes.string,
  away_rbs: PropTypes.string,
  quarter: PropTypes.string,
  fontsize: PropTypes.string,
  clock: PropTypes.string,
  top_line: PropTypes.string,
  bottom_line: PropTypes.string,
};
