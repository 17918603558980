import React from 'react';
import PGA_Ad_Countdown from './PGA_Ad_Countdown';
import Isvg from 'react-inlinesvg';
import './PGA_countdown.scss';

export default class PGA_countdown extends React.Component {
  render() {
    return (
      <div className="PGA_countdown">
        <div className="adContainer">
          <PGA_Ad_Countdown />
        </div>
        <div className="phone">
          <Isvg src="/mobile.svg" />
        </div>
      </div>
    )
  }
}
