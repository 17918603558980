import React from 'react';
import { NavLink, Outlet, useParams } from 'react-router-dom';
import './AdsContainer.scss';
import { ads } from '../data';

const AdsContainer = () => {
  const { adName } = useParams();

  let navs = null;
  if (adName === 'pistons') {
    navs = (
      <ul>
        <li className='hideMobile'>
          <NavLink to={`/${adName}/build`}>Build</NavLink>
        </li>
        <li className='showMobile'>
          <NavLink to={`/${adName}/live`}>Live</NavLink>
        </li>
        <li className='hideMobile'>
          <NavLink to={`/${adName}/sizes`}>Sizes</NavLink>
        </li>
      </ul>
    );
  } else if (adName === 'pepsi') {
    navs = <ul></ul>;
  } else if (adName === 'cowboys') {
    navs = <ul></ul>;
  } else if (adName === 'pga') {
    navs = (
      <ul>
        <li>
          <NavLink to={`/${adName}/live`}>Live</NavLink>
        </li>
        <li className='hideMobile'>
          <NavLink to={`/${adName}/countdown`}>Countdown</NavLink>
        </li>
        <li className='hideMobile'>
          <NavLink to={`/${adName}/highlights`}>Highlights</NavLink>
        </li>
      </ul>
    );
  } else if (adName === 'canucks') {
    navs = (
      <ul>
        <li>
          <NavLink to={`/${adName}/live`}>Live</NavLink>
        </li>
        <li className='hideMobile'>
          <NavLink to={`/${adName}/sizes`}>Sizes</NavLink>
        </li>
      </ul>
    );
  } else if (adName === 'wild') {
    navs = <span></span>;
  } else {
    navs = (
      <ul>
        <li>
          <NavLink to={`/${adName}/live`}>Live</NavLink>
        </li>
      </ul>
    );
  }

  let ad = ads
    .filter(ad => {
      if (ad.short_name === `${adName}`) {
        return ad;
      }
    })
    .map(ad => {
      return ad;
    });

  return (
    <div className='AdsContainer'>
      <div className='AdsContainer_description'>
        <p className='desc_title'>{ad[0].desc_title}</p>
        <p>{ad[0].desc}</p>
      </div>
      {navs}

      <Outlet />
    </div>
  );
};
export default AdsContainer;
