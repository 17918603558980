export const ads = [
  {
    name: 'Pepsi',
    short_name: 'cowboys',
    desc: 'Sample execution using SportServ to extend brand sponsors reach to Cowboys fans everywhere!',
    button_title: 'View Campaign',
    desc_title: 'Cowboys x Pepsi'
  },
  {
    name: 'Pepsi',
    short_name: 'pepsi',
    desc: 'Pepsi. Live Now. As the slogan says, there is nothing more personal, anticipated and relevant in Sports then being live…now.  SportServ combines Pepsi + Sports to deliver all-new fan experiences in the moment they happen.  Live Now!',
    button_title: 'View Campaign',
    desc_title: 'Pepsi'
  },
  {
    name: 'PGA Tour',
    short_name: 'pga',
    desc: 'Market in the moment by combining LIVE tournament action with any creative format or platform to deliver messaging and promotions that are on P.A.R. (Personal, Anticipated, and Relevant).',
    button_title: 'View Campaign',
    desc_title: 'PGA Tour'
  },
  {
    name: 'Minnesota Wild',
    short_name: 'wild',
    desc: 'We built three campaigns around the Minnesota Wild to showcase season-long activation.',
    button_title: 'View Campaign',
    desc_title: 'Minnesota Wild'
  },
  {
    name: 'Detroit Pistons',
    short_name: 'pistons',
    desc: 'Geotargeted to Detroit Pistons fans, this campaign features a data-rich scoreboard and custom messaging based on game conditions, designed to drive tune-in and downloads to the FOX Sports Go app.',
    button_title: 'View Campaign',
    desc_title: 'FOX Sports Go + Detroit Pistons'
  },
  {
    name: 'Vancouver Canucks',
    short_name: 'canucks',
    desc: 'The Canucks campaign features dynamic individual game pricing through a Ticketmaster integration, with variable messaging and imagery based on opponent.',
    button_title: 'View Campaign',
    desc_title: 'Vancouver Canucks'
  },
  {
    name: 'SeatGeek',
    short_name: 'seatgeek',
    desc: 'Live, dynamic SeatGeek prices for every MLB team. This campaign showcases custom ballpark backgrounds specific to every city, along with integrated game stats.',
    button_title: 'View Campaign',
    desc_title: 'SeatGeek'
  },
  {
    name: 'Dick\'s Sporting Goods',
    short_name: 'dsg',
    desc: 'DICK\'S Sporting Goods used location aware banners that feature each NFL team\'s merchandise and custom messaging based on live game stats.',
    button_title: 'View Campaign',
    desc_title: 'DICK\'S Sporting Goods'
  }
]
