import React from 'react';
import { getDayOfYear } from 'date-fns';
import './PGA_Ad_Countdown.scss';

export default class PGA_Ad_Countdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seconds: 45,
    };

    this.clock = null;
    this.tournament_day = getDayOfYear(new Date(2017, 3, 6));
    this.today_day = getDayOfYear(new Date());
  }

  secondCounter() {
    let currentSeconds = this.state.seconds;
    let newSecond = currentSeconds < 59 ? (currentSeconds += 1) : 0;
    this.setState({ seconds: newSecond });
  }

  dateCheck() {
    const diff = this.tournament_day - this.today_day;
    return diff >= 0 ? diff : 3;
  }

  componentDidMount() {
    this.dateCheck();
    this.clock = setInterval(() => this.secondCounter(), 1000);
  }

  componentWillUnmount() {
    window.clearInterval(this.clock);
  }

  render() {
    let seconds = this.state.seconds * 6;
    let rotate = `rotate(${seconds}deg)`;
    const days = this.dateCheck();

    return (
      <div className='PGA_Ad_Countdown'>
        <a href='#' className='ad_container-300x250'>
          <div id='ticker' style={{ transform: rotate }}></div>
          <div className='countdown'>
            <span className='countdown--day'>{days}</span> days until
          </div>
        </a>
      </div>
    );
  }
}
