import React from 'react';
import './PistonsLive_1.scss' ;

export default class PistonsLive_1 extends React.Component {
  render() {
    return (
      <div className="PistonsLive_1">
          <a href="#" className="ad-container loaded">
            <div className="ad-content">
              <div className="scoreboard-wrapper">
                <div className="scoreboard-frame scoreboard-two">
                  <table>
                    <thead>
                      <tr>
                        <td></td>
                        <td>PTS</td>
                        <td>FG%</td>
                        <td>3 PT%</td>
                        <td>FT %</td>
                        <td>RBS</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="table-team">{this.props.away}</td>
                        <td>{this.props.away_pts}</td>
                        <td>{this.props.away_fs}</td>
                        <td>{this.props.away_3pt}</td>
                        <td>{this.props.away_ft}</td>
                        <td>{this.props.away_rbs}</td>
                      </tr>
                      <tr>
                        <td className="table-team">{this.props.home}</td>
                        <td>{this.props.home_pts}</td>
                        <td>{this.props.home_fs}</td>
                        <td>{this.props.home_3pt}</td>
                        <td>{this.props.home_ft}</td>
                        <td>{this.props.home_rbs}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="scoreboard-frame scoreboard-one">
                  <div className="team-box team-away">
                    <div>
                      <img src={`https://fanserv-media.s3.amazonaws.com/client/fox_sports/pistons-tune-in-v2/300x250/dist/images/logo-${this.props.away}.png`} width="75" height="46" className="team-logo" />
                    </div>
                  </div>
                  <div className="score-box score-away">
                    <div className="points points-away">{this.props.home_pts}</div>
                    <div className="fouls-meter fouls-away"><span className="to-abrev">TO</span> {this.props.away_to}</div>
                  </div>
                  <div className="scoreboard-clock">
                    <div className="live">Live</div>
                    <div className="clock-wrapper">
                      <div className="scoreboard-qt">{this.props.quarter}</div>
                      <div className="scoreboard-time">{this.props.clock}</div>
                    </div>
                  </div>
                  <div className="score-box score-home">
                    <div className="points points-home">{this.props.away_pts}</div>
                    <div className="fouls-meter fouls-home"><span className="to-abrev">TO</span> {this.props.home_to}</div>
                  </div>
                  <div className="team-box team-home">
                    <div>
                      <img src={`https://fanserv-media.s3.amazonaws.com/client/fox_sports/pistons-tune-in-v2/300x250/dist/images/logo-${this.props.home}.png`} width="75" height="46" className="team-logo" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="billboard-content">
                <div className="billboard-player">
                  <img src="https://fanserv-media.s3.amazonaws.com/client/fox_sports/pistons-tune-in-v2/300x250/dist/images/player.png" className="player-img" />
                </div>
              </div>
              <div className="game-copy">
                <div className="copy-double line1">{this.props.top_line}</div>
                <div className="copy-double line2">{this.props.bottom_line}</div>
              </div>
              <div className="cta">
                <div className="fox-logo"></div>
                <div className="tune-in-text">
                  <span id="tune-in-copy">Tune In Online</span>
                </div>
              </div>
            </div>
          </a>
      </div>
    )
  }
}
