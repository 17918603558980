import React from 'react';
import { Link } from 'react-router-dom';

import './Header.scss';

const Header = () => (
  <header className='header'>
    <div className='header-wrapper'>
      <h1>
        <a href='https://fanserv.com/sportserv'>
          <svg
            width='130'
            height='33'
            viewBox='0 0 130 33'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
          >
            <title>FanServ Logo</title>
            <defs>
              <path id='a' d='M.01.003v16.694h13.13V.003H.01z' />
            </defs>
            <g fill='none' fillRule='evenodd'>
              <g transform='translate(0 15.73)'>
                <mask id='b' fill='#fff'>
                  <use xlinkHref='#a' />
                </mask>
                <path
                  fill='#12512D'
                  mask='url(#b)'
                  d='M5.127 3.842v3.315h5.605v3.578H5.127v5.962H0V.003h13.14v3.84H5.128'
                />
              </g>
              <path
                d='M19.7 25.25h3.124l-1.43-4.913h-.263L19.7 25.25zm4.126 3.577h-5.128l-1.025 3.602h-5.128l5.055-15.2c.43-1.27.62-1.58 1.766-1.58h4.102c1.145 0 1.36.33 1.765 1.57l5.057 15.2h-5.415l-1.05-3.608zm23.97 3.603H44.24c-1.096 0-1.62-.312-2.193-1.218l-5.152-8.323h-.12v9.54h-4.84v-16.7H36.3c1.096 0 1.645.31 2.193 1.21l4.34 7.32h.12v-8.53h4.842v16.7'
                fill='#12512D'
              />
              <path
                fill='#048843'
                d='M126.222.115l-6.305 4.188h2.253l-4.11 15.06h5.342l4.11-15.06h2.326L126.222.115M63.82 31.618c-.5.5-1.287.81-2.622.81h-6.893c-1.502 0-2.36-.19-2.957-.786-.692-.692-1.025-1.908-1.025-4.842h4.698c.05 1.24.15 1.574.27 1.693.15.143.43.19 1.12.19h2.41c.69 0 1.1-.047 1.22-.166.15-.143.22-.477.22-1.073 0-.477-.07-.81-.212-.954-.12-.12-.574-.215-1.24-.286l-4.94-.573c-1.05-.11-1.74-.38-2.264-.9-.644-.64-1.074-1.64-1.074-3.96 0-2.12.525-3.45 1.29-4.22.5-.5 1.31-.81 2.575-.81h6.41c1.55 0 2.434.24 2.96.76.714.72 1.096 1.77 1.12 4.65h-4.7c-.048-1.05-.12-1.33-.263-1.48-.143-.14-.382-.19-1.002-.19h-2.383c-.573 0-.86.03-.955.12-.12.12-.19.43-.19.98 0 .53.07.81.21.96.12.12.43.17 1.24.26l4.99.58c1.144.143 1.74.405 2.24.906.643.64 1.05 1.69 1.05 3.93 0 2.29-.527 3.65-1.29 4.415m17.46.813H67.64v-16.7h13.64v3.86h-8.515v2.577h6.2v3.58h-6.2v2.815h8.514v3.86m10.39-9.33c.93 0 1.19-.046 1.33-.19.26-.24.38-.643.38-1.62 0-.977-.14-1.36-.38-1.573-.17-.142-.41-.19-1.34-.19H88.9v3.577h2.767zm1.24 3.913c-.24-.216-.46-.31-1.51-.31h-2.5v5.723h-5.12v-16.7h10.66c1.67 0 2.6.217 3.242.86.74.74 1.097 1.576 1.097 3.89 0 3.1-.93 4.03-2.86 4.41v.05c1.67.38 2.622.93 2.693 3.34.048 1.62.167 3.24.263 4.15h-5.223c-.167-1.122-.24-2.267-.287-3.7-.047-1.24-.19-1.48-.453-1.715z'
              />
              <path
                fill='#12512D'
                d='M114.84 7.417h5.342l3.186 11.947h-5.342L114.84 7.417'
              />
              <path
                d='M112.975 30.926c-.382 1.17-.763 1.574-1.908 1.574h-4.054c-1.145 0-1.526-.405-1.908-1.574l-4.794-15.192h5.61l3.15 12.402h.26l5.52-20.72h5.35l-7.206 23.51'
                fill='#048843'
              />
            </g>
          </svg>
        </a>
      </h1>
      <div className='header-right-links'>
        <Link to='/' className='header-campaigns'>
          Campaigns
        </Link>
        <a
          href='https://fanserv.com/sportserv/contact.html'
          target='_blank'
          className='header-contact'
        >
          Contact
        </a>
      </div>
    </div>
  </header>
);

export default Header;
