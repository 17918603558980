import React from 'react';

import DSG_300x250 from './DSG_300x250';
import './DSGLive_All.scss' ;

export default class DSGSize_All extends React.Component {
  constructor() {
    super()
  }

  render() {
    return (
      <div className="DSGLive_All">
        <DSG_300x250
          key={1}
          market={'Denver'}
          team={'Broncos'}
          product_short={'hoodie'}
          product_copy={'Nike Hoodie'}
          team_id={'1174'}
        />

        <DSG_300x250
          key={2}
          market={'Carolina'}
          team={'Panthers'}
          product_short={'hoodie'}
          product_copy={'Nike Hoodie'}
          team_id={'1175'}
        />

        <DSG_300x250
          key={3}
          market={'Tennessee'}
          team={'Titans'}
          product_short={'hoodie'}
          product_copy={'Nike Hoodie'}
          team_id={'1176'}
        />

        <DSG_300x250
          key={4}
          market={'Minnesota'}
          team={'Vikings'}
          product_short={'hoodie'}
          product_copy={'Nike Hoodie'}
          team_id={'1177'}
        />

        <DSG_300x250
          key={5}
          market={'Philladelphia'}
          team={'Eagles'}
          product_short={'hoodie'}
          product_copy={'Nike Hoodie'}
          team_id={'1178'}
        />

        <DSG_300x250
          key={6}
          market={'Cleveland'}
          team={'Browns'}
          product_short={'hoodie'}
          product_copy={'Nike Hoodie'}
          team_id={'1179'}
        />

        <DSG_300x250
          key={7}
          market={'Jacksonville'}
          team={'Jags'}
          product_short={'hoodie'}
          product_copy={'Nike Hoodie'}
          team_id={'1180'}
        />

        <DSG_300x250
          key={8}
          market={'Green Bay'}
          team={'Packers'}
          product_short={'hoodie'}
          product_copy={'Nike Hoodie'}
          team_id={'1181'}
        />

        <DSG_300x250
          key={9}
          market={'Kansas City'}
          team={'Chiefs'}
          product_short={'hoodie'}
          product_copy={'Nike Hoodie'}
          team_id={'1182'}
        />

        <DSG_300x250
          key={10}
          market={'San Diego'}
          team={'Chargers'}
          product_short={'hoodie'}
          product_copy={'Nike Hoodie'}
          team_id={'1183'}
        />

        <DSG_300x250
          key={11}
          market={'Houston'}
          team={'Texans'}
          product_short={'hoodie'}
          product_copy={'Nike Hoodie'}
          team_id={'1184'}
        />

        <DSG_300x250
          key={12}
          market={'Chicago'}
          team={'Bears'}
          product_short={'hoodie'}
          product_copy={'Nike Hoodie'}
          team_id={'1185'}
        />

        <DSG_300x250
          key={13}
          market={'New Orleans'}
          team={'Saints'}
          product_short={'hoodie'}
          product_copy={'Nike Hoodie'}
          team_id={'1186'}
        />

        <DSG_300x250
          key={14}
          market={'Oakland'}
          team={'Raiders'}
          product_short={'hoodie'}
          product_copy={'Nike Hoodie'}
          team_id={'1187'}
        />

        <DSG_300x250
          key={15}
          market={'Atlanta'}
          team={'Falcons'}
          product_short={'hoodie'}
          product_copy={'Nike Hoodie'}
          team_id={'1188'}
        />

        <DSG_300x250
          key={16}
          market={'Tampa Bay'}
          team={'Buccaneers'}
          product_short={'hoodie'}
          product_copy={'Nike Hoodie'}
          team_id={'1189'}
        />

        <DSG_300x250
          key={17}
          market={'Baltimore'}
          team={'Ravens'}
          product_short={'hoodie'}
          product_copy={'Nike Hoodie'}
          team_id={'1190'}
        />

        <DSG_300x250
          key={18}
          market={'Buffalo'}
          team={'Bills'}
          product_short={'hoodie'}
          product_copy={'Nike Hoodie'}
          team_id={'1191'}
        />

        <DSG_300x250
          key={19}
          market={'New York'}
          team={'Jets'}
          product_short={'hoodie'}
          product_copy={'Nike Hoodie'}
          team_id={'1192'}
        />

        <DSG_300x250
          key={20}
          market={'Cincinnati'}
          team={'Bengals'}
          product_short={'hoodie'}
          product_copy={'Nike Hoodie'}
          team_id={'1193'}
        />

        <DSG_300x250
          key={21}
          market={'Seattle'}
          team={'Sea Hawks'}
          product_short={'hoodie'}
          product_copy={'Nike Hoodie'}
          team_id={'1194'}
        />

        <DSG_300x250
          key={22}
          market={'Miami'}
          team={'Dolphins'}
          product_short={'hoodie'}
          product_copy={'Nike Hoodie'}
          team_id={'1195'}
        />

        <DSG_300x250
          key={23}
          market={'Dallas'}
          team={'Cowboys'}
          product_short={'hoodie'}
          product_copy={'Nike Hoodie'}
          team_id={'1196'}
        />

        <DSG_300x250
          key={24}
          market={'New York'}
          team={'Giants'}
          product_short={'hoodie'}
          product_copy={'Nike Hoodie'}
          team_id={'1197'}
        />

        <DSG_300x250
          key={25}
          market={'Indianapolis'}
          team={'Colts'}
          product_short={'hoodie'}
          product_copy={'Nike Hoodie'}
          team_id={'1198'}
        />

        <DSG_300x250
          key={26}
          market={'Detroit'}
          team={'Lions'}
          product_short={'hoodie'}
          product_copy={'Nike Hoodie'}
          team_id={'1199'}
        />

        <DSG_300x250
          key={27}
          market={'Arizona'}
          team={'Cardinals'}
          product_short={'hoodie'}
          product_copy={'Nike Hoodie'}
          team_id={'1200'}
        />

        <DSG_300x250
          key={28}
          market={'New England'}
          team={'Patriots'}
          product_short={'hoodie'}
          product_copy={'Nike Hoodie'}
          team_id={'1201'}
        />

        <DSG_300x250
          key={29}
          market={'Washington'}
          team={'Redskins'}
          product_short={'hoodie'}
          product_copy={'Nike Hoodie'}
          team_id={'1202'}
        />

        <DSG_300x250
          key={30}
          market={'Pittsburgh'}
          team={'Steelers'}
          product_short={'hoodie'}
          product_copy={'Nike Hoodie'}
          team_id={'1203'}
        />

        <DSG_300x250
          key={31}
          market={'San Francisco'}
          team={'49ers'}
          product_short={'hoodie'}
          product_copy={'Nike Hoodie'}
          team_id={'1204'}
        />

        <DSG_300x250
          key={32}
          market={'Los Angeles'}
          team={'Rams'}
          product_short={'hoodie'}
          product_copy={'Nike Hoodie'}
          team_id={'1205'}
        />
      </div>
    )
  }
}
