import React from 'react';

import PistonsLive_1 from './PistonsLive_1';
import './PistonsLive_All.scss' ;

export default class PistonsLive_All extends React.Component {
  render() {
    return (
      <div className="PistonsLive_All">
        <PistonsLive_1
          key="1"
          home="det"
          away="lal"
          home_pts="11"
          away_pts="12"
          home_to="3"
          away_to="4"
          home_fs="62"
          away_fs="72"
          home_3pt="100"
          away_3pt="66"
          home_ft="70"
          away_ft="65"
          home_rbs="5"
          away_rbs="4"
          quarter="Q1"
          fontsize="3.5"
          clock="10:53"
          top_line="1-Point"
          bottom_line="Game"
        />

        <PistonsLive_1
          key="2"
          home="atl"
          away="det"
          home_pts="11"
          away_pts="12"
          home_to="3"
          away_to="4"
          home_fs="62"
          away_fs="72"
          home_3pt="100"
          away_3pt="66"
          home_ft="70"
          away_ft="65"
          home_rbs="5"
          away_rbs="4"
          quarter="Q1"
          fontsize="3.5"
          clock="10:53"
          top_line="1-Point"
          bottom_line="Game"
        />

        <PistonsLive_1
          key="3"
          home="det"
          away="cle"
          home_pts="11"
          away_pts="12"
          home_to="3"
          away_to="4"
          home_fs="62"
          away_fs="72"
          home_3pt="100"
          away_3pt="66"
          home_ft="70"
          away_ft="65"
          home_rbs="5"
          away_rbs="4"
          quarter="Q1"
          fontsize="3.5"
          clock="10:53"
          top_line="1-Point"
          bottom_line="Game"
        />

      <PistonsLive_1
        key="4"
        home="gsw"
        away="det"
        home_pts="11"
        away_pts="12"
        home_to="3"
        away_to="4"
        home_fs="62"
        away_fs="72"
        home_3pt="100"
        away_3pt="66"
        home_ft="70"
        away_ft="65"
        home_rbs="5"
        away_rbs="4"
        quarter="Q1"
        fontsize="3.5"
        clock="10:53"
        top_line="1-Point"
        bottom_line="Game"
      />

      <PistonsLive_1
        key="5"
        home="det"
        away="chi"
        home_pts="11"
        away_pts="12"
        home_to="3"
        away_to="4"
        home_fs="62"
        away_fs="72"
        home_3pt="100"
        away_3pt="66"
        home_ft="70"
        away_ft="65"
        home_rbs="5"
        away_rbs="4"
        quarter="Q1"
        fontsize="3.5"
        clock="10:53"
        top_line="1-Point"
        bottom_line="Game"
      />

      <PistonsLive_1
        key="6"
        home="mil"
        away="det"
        home_pts="11"
        away_pts="12"
        home_to="3"
        away_to="4"
        home_fs="62"
        away_fs="72"
        home_3pt="100"
        away_3pt="66"
        home_ft="70"
        away_ft="65"
        home_rbs="5"
        away_rbs="4"
        quarter="Q1"
        fontsize="3.5"
        clock="10:53"
        top_line="1-Point"
        bottom_line="Game"
      />
    </div>
    )
  }
}
