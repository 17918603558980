import React from 'react';
import { useParams } from 'react-router';

import CanucksSize_All from './ads/canucks/CanucksSize_All';
import PistonsSize_All from './ads/pistons/PistonsSize_All';
// import DSGSize_All from './ads/dsg/DSGSize_All';

const AdsSize = () => {
  const { adName } = useParams();

  let ads = null;
  if (adName === 'pistons') {
    ads = <PistonsSize_All />;
  } else if (adName === 'canucks') {
    ads = <CanucksSize_All key={'CanucksSize_All'} />;
  }

  return <div className='AdsWrapper-300x250'>{ads}</div>;
};

export default AdsSize;
