import React, { Component } from 'react';
import CampaignAlpha_300x250_replybuy from './CampaignAlpha_300x250_replybuy';
import CampaignAlpha_300x250_wendys from './CampaignAlpha_300x250_wendys';
import CampaignAlpha_300x250_cocacola from './CampaignAlpha_300x250_cocacola';
import PropTypes from 'prop-types';

export default class CampaignAlpha_300x250 extends Component {
  render() {
    let campaign_name = null;
    if (this.props.campaign === "Wendy's") {
      campaign_name = (
        <div>
          <CampaignAlpha_300x250_wendys
            game={this.props.game}
            game_state={this.props.game_state}
            game_condition={this.props.game_condition}
            home_team={this.props.home_team}
            home_team_short={this.props.home_team_short}
            away_team={this.props.away_team}
            away_team_short={this.props.away_team_short}
            home_goals={this.props.home_goals}
            aways_goals={this.props.aways_goals}
            clock={this.props.clock}
            period={this.props.period}
          />
        </div>
      );
    } else if (this.props.campaign === 'Coca-Cola') {
      campaign_name = (
        <div>
          <CampaignAlpha_300x250_cocacola
            game={this.props.game}
            game_state={this.props.game_state}
            game_condition={this.props.game_condition}
            home_team={this.props.home_team}
            home_team_short={this.props.home_team_short}
            away_team={this.props.away_team}
            away_team_short={this.props.away_team_short}
            home_goals={this.props.home_goals}
            aways_goals={this.props.aways_goals}
            clock={this.props.clock}
            period={this.props.period}
          />
        </div>
      );
    } else if (this.props.campaign === 'ReplyBuy') {
      campaign_name = (
        <div>
          <CampaignAlpha_300x250_replybuy
            game={this.props.game}
            game_state={this.props.game_state}
            game_condition={this.props.game_condition}
            home_team={this.props.home_team}
            home_team_short={this.props.home_team_short}
            away_team={this.props.away_team}
            away_team_short={this.props.away_team_short}
            home_goals={this.props.home_goals}
            aways_goals={this.props.aways_goals}
            clock={this.props.clock}
            period={this.props.period}
          />
        </div>
      );
    }

    return <div>{campaign_name}</div>;
  }
}

CampaignAlpha_300x250.propTypes = {
  campaign: PropTypes.string,
  game: PropTypes.string,
  game_state: PropTypes.string,
  game_condition: PropTypes.string,
  home_team: PropTypes.string,
  home_team_short: PropTypes.string,
  away_team: PropTypes.string,
  away_team_short: PropTypes.string,
  home_goals: PropTypes.string,
  aways_goals: PropTypes.string,
  clock: PropTypes.string,
  period: PropTypes.string,
};
