export const leagues = [
  { value: 'MLB', label: 'MLB' },
  { value: 'NFL', label: 'NFL' },
  { value: 'NBA', label: 'NBA' }
]

export const platforms = [
  { value: 'Desktop', label: 'Desktop' },
]

export const teams = [
  { value: 'New York Yankees', label: 'New York Yankees', short: 'nyc' },
  { value: 'Chicago Cubs', label: 'Chicago Cubs' },
  { value: 'Cleveland Indians', label: 'Cleveland Indians' },
  { value: 'Kansas City Royals', label: 'Kansas City Royals' },
  { value: 'Seattle Mariners', label: 'Seattle Mariners' },
  { value: 'Minnesota Twins', label: 'Minnesota Twins' },
  { value: 'Arizona Diamondbacks', label: 'Arizona Diamondbacks' },
  { value: 'Florida Marlins', label: 'Florida Marlins' },
  { value: 'Tampa Bay Rays', label: 'Tampa Bay Rays' },
  { value: 'Detroit Tigers', label: 'Detroit Tigers' },
  { value: 'Milwaukee Brewers', label: 'Milwaukee Brewers' }
]

export const leagueTeams = [
  {
    league: 'NFL',
    teams: [
      { value: 'Arizona Cardinals', label: 'Arizona Cardinals', short: 'ARI' },
      { value: 'Atlanta Falcons', label: 'Atlanta Falcons', short: 'ATL' },
      { value: 'Baltimore Ravens', label: 'Baltimore Ravens', short: 'BAL' },
      { value: 'Buffalo Bills', label: 'Buffalo Bills', short: 'BUF' },
      { value: 'Carolina Panthers', label: 'Carolina Panthers', short: 'CAR' },
      { value: 'Chicago Bears', label: 'Chicago Bears', short: 'CHI' },
      { value: 'Cincinnati Bengals', label: 'Cincinnati Bengals', short: 'CIN' },
      { value: 'Cleveland Browns', label: 'Cleveland Browns', short: 'CLE' },
      { value: 'Dallas Cowboys', label: 'Dallas Cowboys', short: 'DAL' },
      { value: 'Denver Broncos', label: 'Denver Broncos', short: 'DEN' },
      { value: 'Detroit Lions', label: 'Detroit Lions', short: 'DET' },
      { value: 'Green Bay Packers', label: 'Green Bay Packers', short: 'GB' },
      { value: 'Houston Texans', label: 'Houston Texans', short: 'HOU' },
      { value: 'Indianapolis Colts', label: 'Indianapolis Colts', short: 'IND' },
      { value: 'Jacksonville Jaguars', label: 'Jacksonville Jaguars', short: 'JAX' },
      { value: 'Kansas City Chiefs', label: 'Kansas City Chiefs', short: 'KC' },
      { value: 'Los Angeles Rams', label: 'Los Angeles Rams', short: 'LA' },
      { value: 'Miami Dolphins', label: 'Miami Dolphins', short: 'MIA' },
      { value: 'Minnesota Vikings', label: 'Minnesota Vikings', short: 'MIN' },
      { value: 'New England Patriots', label: 'New England Patriots', short: 'NE' },
      { value: 'New Orleans Saints', label: 'New Orleans Saints', short: 'NO' },
      { value: 'New York Giants', label: 'New York Giants', short: 'NYG' },
      { value: 'New York Jets', label: 'New York Jets', short: 'NYJ' },
      { value: 'Oakland Raiders', label: 'Oakland Raiders', short: 'OAK' },
      { value: 'Philadelphia Eagles', label: 'Philadelphia Eagles', short: 'PHI' },
      { value: 'Pittsburgh Steelers', label: 'Pittsburgh Steelers', short: 'PIT' },
      { value: 'San Diego Chargers', label: 'San Diego Chargers', short: 'SD' },
      { value: 'San Francisco 49ers', label: 'San Francisco 49ers', short: 'SF' },
      { value: 'Seattle Seahawks', label: 'Seattle Seahawks', short: 'SEA' },
      { value: 'Tampa Bay Buccaneers', label: 'Tampa Bay Buccaneers', short: 'TB' },
      { value: 'Tennessee Titans', label: 'Tennessee Titans', short: 'TEN' },
      { value: 'Washington Redskins', label: 'Washington Redskins', short: 'WAS' }
    ]
  },
  {
    league: 'MLB',
    teams: [
      { value: 'Anaheim Angels', label: 'Anaheim Angels', short: 'ANA' },
      { value: 'Arizona Diamondbacks', label: 'Arizona Diamondbacks', short: 'ARI', pepsi_team: true },
      { value: 'Atlanta Braves', label: 'Atlanta Braves', short: 'ATL' },
      { value: 'Baltimore Orioles', label: 'Baltimore Orioles', short: 'BAL' },
      { value: 'Boston Red Sox', label: 'Boston Red Sox', short: 'BOS' },
      { value: 'Chicago Cubs', label: 'Chicago Cubs', short: 'CHC', pepsi_team: true },
      { value: 'Chicago White Sox', label: 'Chicago White Sox', short: 'CHW' },
      { value: 'Cincinnati Reds', label: 'Cincinnati Reds', short: 'CIN' },
      { value: 'Cleveland Indians', label: 'Cleveland Indians', short: 'CLE', pepsi_team: true },
      { value: 'Colorado Rockies', label: 'Colorado Rockies', short: 'COL' },
      { value: 'Detroit Tigers', label: 'Detroit Tigers', short: 'DET', pepsi_team: true },
      { value: 'Houston Astros', label: 'Houston Astros', short: 'HOU' },
      { value: 'Kansas City Royals', label: 'Kansas City Royals', short: 'KC', pepsi_team: true },
      { value: 'Los Angeles Dodgers', label: 'Los Angeles Dodgers', short: 'LAD' },
      { value: 'Miami Marlins', label: 'Miami Marlins', short: 'MIA', pepsi_team: true },
      { value: 'Milwaukee Brewers', label: 'Milwaukee Brewers', short: 'MIL', pepsi_team: true },
      { value: 'Minnesota Twins', label: 'Minnesota Twins', short: 'MIN', pepsi_team: true },
      { value: 'New York Mets', label: 'New York Mets', short: 'NYM' },
      { value: 'New York Yankees', label: 'New York Yankees', short: 'NYY', pepsi_team: true },
      { value: 'Oakland Athletics', label: 'Oakland Athletics', short: 'OAK' },
      { value: 'Philadelphia Phillies', label: 'Philadelphia Phillies', short: 'PHI' },
      { value: 'Pittsburgh Pirates', label: 'Pittsburgh Pirates', short: 'PIT' },
      { value: 'St. Louis Cardinals', label: 'St. Louis Cardinals', short: 'STL' },
      { value: 'San Diego Padres', label: 'San Diego Padres', short: 'SD' },
      { value: 'San Francisco Giants', label: 'San Francisco Giants', short: 'SF' },
      { value: 'Seattle Mariners', label: 'Seattle Mariners', short: 'SEA', pepsi_team: true },
      { value: 'Tampa Bay Rays', label: 'Tampa Bay Rays', short: 'TB', pepsi_team: true },
      { value: 'Texas Rangers', label: 'Texas Rangers', short: 'TEX' },
      { value: 'Toronto Blue Jays', label: 'Toronto Blue Jays', short: 'TOR' },
      { value: 'Washington Nationals', label: 'Washington Nationals', short: 'WSH' }
    ]
  },
  {
    league: 'NBA',
    teams: [
      { value: 'Atlanta Hawks', label: 'Atlanta Hawks', short: 'ATL' },
      { value: 'Boston Celtics', label: 'Boston Celtics', short: 'BOS' },
      { value: 'Brooklyn Nets', label: 'Brooklyn Nets', short: 'BKN' },
      { value: 'Charlotte Hornets', label: 'Charlotte Hornets', short: 'CHA' },
      { value: 'Chicago Bulls', label: 'Chicago Bulls', short: 'CHI' },
      { value: 'Cleveland Cavaliers', label: 'Cleveland Cavaliers', short: 'CLE' },
      { value: 'Dallas Mavericks', label: 'Dallas Mavericks', short: 'DAL' },
      { value: 'Denver Nuggets', label: 'Denver Nuggets', short: 'DEN' },
      { value: 'Detroit Pistons', label: 'Detroit Pistons', short: 'DET' },
      { value: 'Golden State Warriors', label: 'Golden State Warriors', short: 'GSW' },
      { value: 'Houston Rockets', label: 'Houston Rockets', short: 'HOU' },
      { value: 'Indiana Pacers', label: 'Indiana Pacers', short: 'IND' },
      { value: 'Los Angeles Clippers', label: 'Los Angeles Clippers', short: 'LAC' },
      { value: 'Los Angeles Lakers', label: 'Los Angeles Lakers', short: 'LAL' },
      { value: 'Memphis Grizzlies', label: 'Memphis Grizzlies', short: 'MEM' },
      { value: 'Miami Heat', label: 'Miami Heat', short: 'MIA' },
      { value: 'Milwaukee Bucks', label: 'Milwaukee Bucks', short: 'MIL' },
      { value: 'Minnesota Timberwolves', label: 'Minnesota Timberwolves', short: 'MIN' },
      { value: 'New Orleans Pelicans', label: 'New Orleans Pelicans', short: 'NOP' },
      { value: 'New York Knicks', label: 'New York Knicks', short: 'NYK' },
      { value: 'Oklahoma City Thunder', label: 'Oklahoma City Thunder', short: 'OKC' },
      { value: 'Orlando Magic', label: 'Orlando Magic', short: 'ORL' },
      { value: 'Philadelphia 76ers', label: 'Philadelphia 76ers', short: 'PHI' },
      { value: 'Phoenix Suns', label: 'Phoenix Suns', short: 'PHX' },
      { value: 'Portland Trail Blazers', label: 'Portland Trail Blazers', short: 'POR' },
      { value: 'Sacramento Kings', label: 'Sacramento Kings', short: 'SAC' },
      { value: 'San Antonio Spurs', label: 'San Antonio Spurs', short: 'SAS' },
      { value: 'Toronto Raptors', label: 'Toronto Raptors', short: 'TOR' },
      { value: 'Utah Jazz', label: 'Utah Jazz', short: 'UTA' },
      { value: 'Washington Wizards', label: 'Washington Wizards', short: 'WAS' }
    ]
  }
]

export const games = [
  { value: 'Baltimore Orioles', label: 'Baltimore Orioles' },
  { value: 'Boston Red Sox', label: 'Boston Red Sox' },
  { value: 'Chicago White Sox', label: 'Chicago White Sox' },
  { value: 'Cleveland Indians', label: 'Cleveland Indians' },
  { value: 'Detroit Tigers', label: 'Detroit Tigers' },
  { value: 'Houston Astros', label: 'Houston Astros' },
  { value: 'Kansas City Royals', label: 'Kansas City Royals' },
  { value: 'Los Angeles Angels', label: 'Los Angeles Angels' },
  { value: 'Minnesota Twins', label: 'Minnesota Twins' },
  { value: 'New York Yankees', label: 'New York Yankees' },
  { value: 'Oakland Athletics', label: 'Oakland Athletics' },
  { value: 'Seattle Mariners', label: 'Seattle Mariners' },
  { value: 'Tampa Bay Rays', label: 'Tampa Bay Rays' },
  { value: 'Texas Rangers', label: 'Texas Rangers' },
  { value: 'Toronto Blue Jays', label: 'Toronto Blue Jays' },
  { value: 'Arizona Diamondbacks', label: 'Arizona Diamondbacks' },
  { value: 'Atlanta Braves', label: 'Atlanta Braves' },
  { value: 'Chicago Cubs', label: 'Chicago Cubs' },
  { value: 'Cincinnati Reds', label: 'Cincinnati Reds' },
  { value: 'Colorado Rockies', label: 'Colorado Rockies' },
  { value: 'Los Angeles Dodgers', label: 'Los Angeles Dodgers' },
  { value: 'Miami Marlins', label: 'Miami Marlins' },
  { value: 'Milwaukee Brewers', label: 'Milwaukee Brewers' },
  { value: 'New York Mets', label: 'New York Mets' },
  { value: 'Philadelphia Phillies', label: 'Philadelphia Phillies' },
  { value: 'Pittsburgh Pirates', label: 'Pittsburgh Pirates' },
  { value: 'San Diego Padres', label: 'San Diego Padres' },
  { value: 'San Francisco Giants', label: 'San Francisco Giants' },
  { value: 'St. Louis Cardinals', label: 'St. Louis Cardinals' },
  { value: 'Washington National', label: 'Washington National' }
]

export const game_states = [
  { value: 'Pregame', label: 'Pregame' },
  { value: 'Live', label: 'Live' },
  { value: 'Postgame', label: 'Postgame' }
]

export const game_conditions = [
  { value: 'Countdown', label: 'Countdown' },
  { value: 'Pistons Up 10', label: 'Pistons Up 10' },
  { value: 'Pistons Down 2', label: 'Pistons Down 2' },
  { value: 'Halftime', label: 'Halftime' },
  { value: 'Tight Game', label: 'Tight Game' },
  { value: '1 Point Game in 4th Qtr', label: '1 Point Game in 4th Qtr' },
  { value: 'Home Win', label: 'Home Win' },
  { value: 'Home Loss', label: 'Home Loss' }
]

export const game_cons = [
  {
    league: 'NFL',
    game_states: [
      {
        status: 'Pregame',
        value: 'Countdown',
        label: 'Countdown',
        stats: {
          status_first: '22 min',
          status_second: 'til Gametime',
          home_pts: 0,
          away_pts: 0,
          quarter: 0,
          clock: '0:00',
          home_1: 0,
          home_2: 0,
          home_3: 0,
          home_4: 0,
          home_ot: 0,
          away_1: 0,
          away_2: 0,
          away_3: 0,
          away_4: 0,
          away_ot: 0
        }
      },
      {
        status: 'Live',
        value: 'Home Ahead',
        label: 'Home Ahead',
        stats: {
          status_first: '2nd Quarter',
          status_second: '8:31',
          home_pts: 10,
          away_pts: 3,
          quarter: 2,
          clock: '8:31',
          home_1: 3,
          home_2: 7,
          home_3: 0,
          home_4: 0,
          home_ot: 0,
          away_1: 3,
          away_2: 0,
          away_3: 0,
          away_4: 0,
          away_ot: 0
        }
      },
      {
        status: 'Live',
        value: 'Away Ahead',
        label: 'Away Ahead',
        stats: {
          status_first: '3rd Quarter',
          status_second: '2:01',
          home_pts: 20,
          away_pts: 28,
          quarter: 3,
          clock: '2:01',
          home_1: 3,
          home_2: 7,
          home_3: 10,
          home_4: 0,
          home_ot: 0,
          away_1: 7,
          away_2: 12,
          away_3: 9,
          away_4: 0,
          away_ot: 0
        }
      },
      {
        status: 'Postgame',
        value: 'Home Win',
        label: 'Home Win',
        stats: {
          status_first: 'Final',
          status_second: '',
          home_pts: 31,
          away_pts: 28,
          quarter: 4,
          clock: 'Final',
          home_1: 3,
          home_2: 7,
          home_3: 10,
          home_4: 11,
          home_ot: 0,
          away_1: 7,
          away_2: 12,
          away_3: 9,
          away_4: 0,
          away_ot: 0
        }
      },
      {
        status: 'Postgame',
        value: 'Away Win',
        label: 'Away Win',
        stats: {
          status_first: 'Final',
          status_second: '',
          home_pts: 28,
          away_pts: 35,
          quarter: 4,
          clock: 'Final',
          home_1: 3,
          home_2: 7,
          home_3: 10,
          home_4: 7,
          home_ot: 0,
          away_1: 7,
          away_2: 12,
          away_3: 9,
          away_4: 7,
          away_ot: 0
        }
      }
    ]
  },
  {
    league: 'NBA',
    game_states: [
      {
        status: 'Pregame',
        value: 'Countdown',
        label: 'Countdown',
        stats: {
          status_first: '13 Min',
          status_second: 'Til Gametime',
          home_pts: 0,
          away_pts: 0,
          quarter: 0,
          clock: '0:00',
          home_1: 0,
          home_2: 0,
          home_3: 0,
          home_4: 0,
          home_ot: 0,
          away_1: 0,
          away_2: 0,
          away_3: 0,
          away_4: 0,
          away_ot: 0
        }
      },
      {
        status: 'Live',
        value: 'Home Ahead',
        label: 'Home Ahead',
        stats: {
          status_first: '1st Qt',
          status_second: '0:31',
          home_pts: 22,
          away_pts: 21,
          quarter: 1,
          clock: '0:31',
          home_1: 22,
          home_2: 0,
          home_3: 0,
          home_4: 0,
          home_ot: 0,
          away_1: 21,
          away_2: 0,
          away_3: 0,
          away_4: 0,
          away_ot: 0
        }
      },
      {
        status: 'Live',
        value: 'Away Ahead',
        label: 'Away Ahead',
        stats: {
          status_first: '1st Qt',
          status_second: '0:11',
          home_pts: 18,
          away_pts: 28,
          quarter: 1,
          clock: '0:11',
          home_1: 18,
          home_2: 0,
          home_3: 0,
          home_4: 0,
          home_ot: 0,
          away_1: 28,
          away_2: 0,
          away_3: 0,
          away_4: 0,
          away_ot: 0
        }
      },
      {
        status: 'Postgame',
        value: 'Home Win',
        label: 'Home Win',
        stats: {
          status_first: 'Final',
          status_second: '',
          home_pts: 98,
          away_pts: 90,
          quarter: 4,
          clock: 'Final',
          home_1: 22,
          home_2: 18,
          home_3: 19,
          home_4: 39,
          home_ot: 0,
          away_1: 27,
          away_2: 12,
          away_3: 29,
          away_4: 22,
          away_ot: 0
        }
      },
      {
        status: 'Postgame',
        value: 'Away Win',
        label: 'Away Win',
        stats: {
          status_first: 'Final',
          status_second: '',
          home_pts: 99,
          away_pts: 103,
          quarter: 4,
          clock: 'Final',
          home_1: 23,
          home_2: 19,
          home_3: 27,
          home_4: 30,
          home_ot: 0,
          away_1: 22,
          away_2: 27,
          away_3: 22,
          away_4: 32,
          away_ot: 0
        }
      }
    ]
  },
  {
    league: 'MLB',
    game_states: [
      {
        status: 'Pregame',
        value: 'Countdown',
        label: 'Countdown',
        stats: {
          status_first: '19 Min',
          status_second: 'Til Gametime',
          home_pts: 0,
          away_pts: 0,
          quarter: 0,
          clock: '0:00',
          home_1: 0,
          home_2: 0,
          home_3: 0,
          home_4: 0,
          home_5: 0,
          home_6: 0,
          home_7: 0,
          home_8: 0,
          home_9: 0,
          home_ot: 0,
          away_1: 0,
          away_2: 0,
          away_3: 0,
          away_4: 0,
          away_5: 0,
          away_6: 0,
          away_7: 0,
          away_8: 0,
          away_9: 0,
          away_ot: 0
        }
      },
      {
        status: 'Live',
        value: 'Home Ahead',
        label: 'Home Ahead',
        stats: {
          status_first: 'Bottom 5th',
          status_second: '2 Out',
          home_pts: 5,
          away_pts: 0,
          quarter: 5,
          clock: '2 Out',
          home_1: 0,
          home_2: 1,
          home_3: 0,
          home_4: 4,
          home_5: 0,
          home_6: 0,
          home_7: 0,
          home_8: 0,
          home_9: 0,
          away_1: 0,
          away_2: 0,
          away_3: 0,
          away_4: 0,
          away_5: 0,
          away_6: 0,
          away_7: 0,
          away_8: 0,
          away_9: 0
        }
      },
      {
        status: 'Live',
        value: 'Away Ahead',
        label: 'Away Ahead',
        stats: {
          status_first: 'Top 6th',
          status_second: '1 Out',
          home_pts: 4,
          away_pts: 7,
          quarter: 6,
          clock: '1 Out',
          home_1: 0,
          home_2: 2,
          home_3: 0,
          home_4: 2,
          home_5: 0,
          home_6: 0,
          home_7: 0,
          home_8: 0,
          home_9: 0,
          away_1: 0,
          away_2: 0,
          away_3: 0,
          away_4: 4,
          away_5: 0,
          away_6: 3,
          away_7: 0,
          away_8: 0,
          away_9: 0
        }
      },
      {
        status: 'Postgame',
        value: 'Home Win',
        label: 'Home Win',
        stats: {
          status_first: 'Final',
          status_second: '',
          home_pts: 8,
          away_pts: 7,
          quarter: 9,
          clock: '1 Out',
          home_1: 0,
          home_2: 2,
          home_3: 0,
          home_4: 2,
          home_5: 0,
          home_6: 6,
          home_7: 0,
          home_8: 0,
          home_9: 2,
          away_1: 0,
          away_2: 0,
          away_3: 0,
          away_4: 4,
          away_5: 0,
          away_6: 3,
          away_7: 0,
          away_8: 0,
          away_9: 0
        }
      },
      {
        status: 'Postgame',
        value: 'Away Win',
        label: 'Away Win',
        stats: {
          status_first: 'Final',
          status_second: '',
          home_pts: 8,
          away_pts: 9,
          quarter: 9,
          clock: '1 Out',
          home_1: 0,
          home_2: 2,
          home_3: 0,
          home_4: 2,
          home_5: 0,
          home_6: 6,
          home_7: 0,
          home_8: 0,
          home_9: 2,
          away_1: 0,
          away_2: 0,
          away_3: 0,
          away_4: 4,
          away_5: 0,
          away_6: 3,
          away_7: 2,
          away_8: 0,
          away_9: 1
        }
      }
    ]
  }
]
