import React from 'react';

import PepsiBuild from './ads/pepsi/PepsiBuild';
import CowboysBuild from './ads/cowboys/PepsiBuild';
import { useParams } from 'react-router';

const AdsVideo = () => {
  const { adName } = useParams();

  let ads = null;
  if (adName === 'pepsi') {
    ads = <PepsiBuild key={'PepsiBuild_All'} />;
  } else if (adName === 'cowboys') {
    ads = <CowboysBuild key={'CowboysBuild'} />;
  }

  return <div className='AdsWrapper-300x250'>{ads}</div>;
};

export default AdsVideo;
