import React from 'react';
import PepsiVideo_Ad_830x300 from './PepsiVideo_Ad_830x300';
// import PistonsBuild_728x90 from '../pistons/PistonsBuild_728x90';
import './BuildPlatform.scss';
import PropTypes from 'prop-types';

export default class BuildPlatform extends React.Component {
  constructor() {
    super();
  }

  render() {
    const default_platform = this.props.platform;

    let platformVersion = null;
    if (default_platform === 'Desktop') {
      platformVersion = (
        <div className='BuildPlatform' key={'desktop'}>
          <div className='adsContainer'>
            <div className='adContainer_830x300 desktop'>
              <PepsiVideo_Ad_830x300
                {...this.props}
                key='PistonsBuild_728x90_desktop'
              />
            </div>

            <div className='platformContainer'>
              <img src='/browser3.png' />
            </div>
          </div>
        </div>
      );
    }

    return <div>{platformVersion}</div>;
  }
}

BuildPlatform.propTypes = {
  game: PropTypes.string,
  game_short: PropTypes.string,
  game_state: PropTypes.string,
  game_condition: PropTypes.string,
  platform: PropTypes.string,
};

BuildPlatform.propTypes = {
  game_short: PropTypes.string,
  condition: PropTypes.string,
  home_pts: PropTypes.string,
  away_pts: PropTypes.string,
  home_to: PropTypes.string,
  away_to: PropTypes.string,
  home_fs: PropTypes.string,
  away_fs: PropTypes.string,
  home_3pt: PropTypes.string,
  away_3pt: PropTypes.string,
  home_ft: PropTypes.string,
  away_ft: PropTypes.string,
  home_rbs: PropTypes.string,
  away_rbs: PropTypes.string,
  quarter: PropTypes.string,
  fontsize: PropTypes.string,
  clock: PropTypes.string,
  top_line: PropTypes.string,
  bottom_line: PropTypes.string,
};
