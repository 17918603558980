import React from 'react';
import PepsiVideo from './pieces/PepsiVideo';
import NFLBoxScore from './pieces/NFLBoxScore';
import NBABoxScore from './pieces/NBABoxScore';
import MLBBoxScore from './pieces/MLBBoxScore';
import PropTypes from 'prop-types';

import './PepsiVideo_Ad_830x300.scss';

export default class PepsiVideo_Ad_830x300 extends React.Component {
  constructor() {
    super();
  }

  render() {
    let boxScore = null;
    if (this.props.league === 'MLB') {
      boxScore = <MLBBoxScore {...this.props} />;
    } else if (this.props.league === 'NFL') {
      boxScore = <NFLBoxScore {...this.props} />;
    } else if (this.props.league === 'NBA') {
      boxScore = <NBABoxScore {...this.props} />;
    }

    return (
      <div className='PepsiVideo_Ad_830x300'>
        <div className='videoContainer'>
          <PepsiVideo league={this.props.league} />
        </div>

        <div className='liveStrobe'></div>

        <div className='adContent'>
          <div className='pepsiContent'>
            <div className='logoBlock'>
              <div className='pepsiLogo'>
                <img
                  src={`${process.env.PUBLIC_URL}/images/pepsi_logo_small.svg`}
                />
              </div>
            </div>
            <div className='main-tagline'>The Official Soft Drink Of</div>
            {this.props.home_side !== null && (
              <div className='team-tagline'>The {this.props.home_side}</div>
            )}
          </div>
          <div className='gameSummary'>
            <div className='team team-away'>
              {this.props.away_short !== null && (
                <div className='team-city'>
                  <img
                    src={`/team_logos/${this.props.league.toLowerCase()}-${
                      this.props.away_short
                    }.svg`}
                  />
                </div>
              )}
            </div>
            <div className='scoreSummary'>
              <div className='score'>
                {this.props.away_pts} <span className='dash'>-</span>{' '}
                {this.props.home_pts}
              </div>
              <div className='status'>
                {this.props.status_first}
                <br /> {this.props.status_second}
              </div>
            </div>
            <div className='team team-home'>
              {this.props.home_short !== null && (
                <div className='team-city'>
                  <img
                    src={`/team_logos/${this.props.league.toLowerCase()}-${
                      this.props.home_short
                    }.svg`}
                  />
                </div>
              )}
            </div>
          </div>
          {boxScore}
        </div>
      </div>
    );
  }
}

PepsiVideo_Ad_830x300.propTypes = {
  home_side: PropTypes.string,
  away_side: PropTypes.string,
  home_short: PropTypes.string,
  away_short: PropTypes.string,
  league: PropTypes.string,
};
