export const leagues = [
  { value: 'NHL', label: 'NHL' }
]

export const campaigns = [
  { value: 'Wendy\'s', label: 'Wendy\'s' },
  { value: 'Coca-Cola', label: 'Coca-Cola' },
  { value: 'ReplyBuy', label: 'ReplyBuy' }
]

export const teams = [
  { value: 'Minnesota Wild', label: 'Minnesota Wild' }
]

export const platforms = [
  { value: 'Desktop (728x90 and 300x250)', label: 'Desktop (728x90 and 300x250)' },
  { value: 'Mobile Web (300x250)', label: 'Mobile Web (300x250)' }
]

export const games = [
  { value: 'Anaheim Ducks', label: 'Anaheim Ducks' },
  { value: 'Boston Bruins', label: 'Boston Bruins' },
  { value: 'Buffalo Sabres', label: 'Buffalo Sabres' },
  { value: 'Calgary Flames', label: 'Calgary Flames' },
  { value: 'Carolina Hurricanes', label: 'Carolina Hurricanes' },
  { value: 'Chicago Blackhawks', label: 'Chicago Blackhawks' },
  { value: 'Colorado Avalanche', label: 'Colorado Avalanche' },
  { value: 'Columbus Blue Jackets', label: 'Columbus Blue Jackets' },
  { value: 'Dallas Stars', label: 'Dallas Stars' },
  { value: 'Detroit Red Wings', label: 'Detroit Red Wings' },
  { value: 'Edmonton Oilers', label: 'Edmonton Oilers' },
  { value: 'Florida Panthers', label: 'Florida Panthers' },
  { value: 'Los Angeles Kings', label: 'Los Angeles Kings' },
  // { value: 'Minnesota Wild', label: 'Minnesota Wild' },
  { value: 'Montreal Canadiens', label: 'Montreal Canadiens' },
  { value: 'Nashville Predators', label: 'Nashville Predators' },
  { value: 'New Jersey Devils', label: 'New Jersey Devils' },
  { value: 'New York Islanders', label: 'New York Islanders' },
  { value: 'New York Rangers', label: 'New York Rangers' },
  { value: 'Ottawa Senators', label: 'Ottawa Senators' },
  { value: 'Philadelphia Flyers', label: 'Philadelphia Flyers' },
  { value: 'Phoenix Coyotes', label: 'Phoenix Coyotes' },
  { value: 'Pittsburgh Penguins', label: 'Pittsburgh Penguins' },
  { value: 'Saint Louis Blues', label: 'Saint Louis Blues' },
  { value: 'San Jose Sharks', label: 'San Jose Sharks' },
  { value: 'Tampa Bay Lighting', label: 'Tampa Bay Lighting' },
  { value: 'Toronto Maple Leafs', label: 'Toronto Maple Leafs' },
  { value: 'Vancouver Canucks', label: 'Vancouver Canucks' },
  { value: 'Washington Capitals', label: 'Washington Capitals' },
  { value: 'Winnipeg Jets', label: 'Winnipeg Jets' }
]

export const game_states = [
  { value: 'Pregame', label: 'Pregame' },
  { value: 'Live', label: 'Live' },
  { value: 'Postgame', label: 'Postgame' }
]

export const game_conditions = {
  "Wendy's": {
    "Pregame": [
      { value: 'Countdown', label: 'Countdown' }
    ],
    "Live": [
      { value: 'Wild 1 Goal', label: 'Wild 1 Goal' },
      { value: 'Wild 2 Goals', label: 'Wild 2 Goals' },
      { value: 'Wild 3 Goals', label: 'Wild 3 Goals' },
      { value: 'Wild 4 Goals', label: 'Wild 4 Goals' },
      // { value: 'Intermission', label: 'Intermission' }
    ],
    "Postgame": [
      { value: 'Home Win', label: 'Home Win' },
      { value: 'Home Loss', label: 'Home Loss' }
    ]
  },
  "ReplyBuy": {
    "Pregame": [
      { value: 'Countdown', label: 'Countdown' }
    ],
    "Live": [
      { value: 'Wild Up', label: 'Wild Up' },
      { value: 'Intermission', label: 'Intermission' }
    ],
    "Postgame": [
      { value: 'Home Win', label: 'Home Win' },
      { value: 'Home Loss', label: 'Home Loss' },
    ]
  },
  "Coca-Cola": {
    "Pregame": [
      { value: 'Countdown', label: 'Countdown' }
    ],
    "Live": [
      { value: 'Wild 1 Goal', label: 'Wild 1 Goal' },
      { value: 'Wild 2 Goals', label: 'Wild 2 Goals' },
      { value: 'Wild 3 Goals', label: 'Wild 3 Goals' },
      { value: 'Wild 4 Goals', label: 'Wild 4 Goals' },
      // { value: 'Intermission', label: 'Intermission' }
    ],
    "Postgame": [
      { value: 'Home Win', label: 'Home Win' },
      { value: 'Home Loss', label: 'Home Loss' }
    ]
  },
  "ReplyBuy": {
    "Pregame": [
      { value: 'Countdown', label: 'Countdown' }
    ],
    "Live": [
      { value: 'Wild Up', label: 'Wild Up' },
      { value: 'Intermission', label: 'Intermission' }
    ],
    "Postgame": [
      { value: 'Home Win', label: 'Home Win' },
      { value: 'Home Loss', label: 'Home Loss' },
    ]
  }
}

export const game_stats = {
  "Wendy's": {
    "Pregame": {
      "Countdown": {
        home_goals: "0",
        aways_goals: "0",
        clock: null,
        period: "7:30pm"
      }
    },
    "Live": {
      "Wild 1 Goal": {
        home_goals: "1",
        aways_goals: "0",
        clock: "17:12",
        period: "1st"
      },
      "Wild 2 Goals": {
        home_goals: "2",
        aways_goals: "0",
        clock: "4:02",
        period: "1st"
      },
      "Wild 3 Goals": {
        home_goals: "3",
        aways_goals: "1",
        clock: "11:01",
        period: "2nd"
      },
      "Wild 4 Goals": {
        home_goals: "4",
        aways_goals: "1",
        clock: "3:45",
        period: "3rd"
      },
      "Intermission": {
        home_goals: "2",
        aways_goals: "1",
        clock: "0:00",
        period: "Int"
      },
    },
    "Postgame": {
      "Home Win": {
        home_goals: "4",
        aways_goals: "1",
        clock: null,
        period: "Final"
      },
      "Home Loss": {
        home_goals: "2",
        aways_goals: "3",
        clock: null,
        period: "Final"
      },
    }
  },
  "ReplyBuy": {
    "Pregame": {
      "Countdown": {
        home_goals: "0",
        aways_goals: "0",
        "clock": null,
        period: null
      }
    },
    "Live": {
      "Wild Up": {
        home_goals: "2",
        aways_goals: "1",
        clock: null,
        period: "1st"
      },
      "Intermission": {
        home_goals: "2",
        aways_goals: "2",
        clock: null,
        period: "Int"
      }
    },
    "Postgame": {
      "Home Win": {
        home_goals: "4",
        aways_goals: "1",
        clock: null,
        period: "Game Over"
      },
      "Home Loss": {
        home_goals: "2",
        aways_goals: "3",
        clock: null,
        period: "Game Over"
      }
    }
  },
  "Coca-Cola": {
    "Pregame": {
      "Countdown": {
        home_goals: "0",
        aways_goals: "0",
        clock: null,
        period: "7:30pm"
      }
    },
    "Live": {
      "Wild 1 Goal": {
        home_goals: "1",
        aways_goals: "0",
        clock: "17:12",
        period: "1st"
      },
      "Wild 2 Goals": {
        home_goals: "2",
        aways_goals: "0",
        clock: "4:02",
        period: "1st"
      },
      "Wild 3 Goals": {
        home_goals: "3",
        aways_goals: "1",
        clock: "11:01",
        period: "2nd"
      },
      "Wild 4 Goals": {
        home_goals: "4",
        aways_goals: "1",
        clock: "3:45",
        period: "3rd"
      },
      "Intermission": {
        home_goals: "2",
        aways_goals: "1",
        clock: "0:00",
        period: "Int"
      },
    },
    "Postgame": {
      "Home Win": {
        home_goals: "4",
        aways_goals: "1",
        clock: null,
        period: "Final"
      },
      "Home Loss": {
        home_goals: "2",
        aways_goals: "3",
        clock: null,
        period: "Final"
      },
    }
  },
  "ReplyBuy": {
    "Pregame": {
      "Countdown": {
        home_goals: "0",
        aways_goals: "0",
        "clock": null,
        period: null
      }
    },
    "Live": {
      "Wild Up": {
        home_goals: "2",
        aways_goals: "1",
        clock: null,
        period: "1st"
      },
      "Intermission": {
        home_goals: "2",
        aways_goals: "2",
        clock: null,
        period: "Int"
      }
    },
    "Postgame": {
      "Home Win": {
        home_goals: "4",
        aways_goals: "1",
        clock: null,
        period: "Game Over"
      },
      "Home Loss": {
        home_goals: "2",
        aways_goals: "3",
        clock: null,
        period: "Game Over"
      }
    }
  }
}
