import React from 'react';
import './SeatGeekSize_300x250.scss';
import PropTypes from 'prop-types';
class SeatGeekSize_300x250 extends React.Component {
  constructor(props) {
    super(props);

    this.inning_language = {
      inning_1: '1st',
      inning_2: '2nd',
      inning_3: '3rd',
      inning_4: '4th',
      inning_5: '5th',
      inning_6: '6th',
      inning_7: '7th',
      inning_8: '8th',
      inning_9: '9th',
    };

    this.state = {
      load: 'loading',
      price: 0,
      inning: this.inning_language[`inning_${this.randomNum(1, 9)}`],
      points_home: this.randomNum(0, 12),
      points_away: this.randomNum(0, 12),
      inning_top_end:
        Math.round(this.randomNum(2, 3) % 2) === 0 ? 'top' : 'bottom',
    };
  }

  randomNum(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  componentDidMount() {
    let randTime = this.randomNum(150, 1000);
    let randPrice = this.randomNum(6, 45);
    this.setState({ price: randPrice });
    this._timeoutId = setTimeout(
      () => this.setState({ load: 'loaded' }),
      randTime
    );
  }

  componentWillUnmount() {
    clearTimeout(this._timeoutId);
  }

  render() {
    return (
      <div className='SeatGeekSize_300x250'>
        <a
          href='#'
          className={`ad-container medium-rectangle live ${this.props.home}`}
          id='js-ad'
        >
          <div className='live-icon'>
            Live <span className='strobe'></span>
          </div>
          <div className='seatgeek-branding'>SeatGeek</div>

          <div className='banner'>
            You can still get
            <br />
            tickets
          </div>

          {/* Conditionally change button with text. Not sure this is the best way. */}
          <div className={`btn ${this.state.load}`} id='js-btn'>
            {this.state.load === 'loading' ? (
              <div>
                <span className='loader'>
                  <img src='https://fanserv-media.s3.amazonaws.com/client/seatgeek/06-2016/300x250/loader.gif' />
                </span>
                Finding great seats
              </div>
            ) : (
              <div>Great seats from ${this.state.price}</div>
            )}
          </div>

          <div className='ticker-gradient ticker-gradient-l'></div>
          {/* TODO: find game info */}
          <div className='boxscore-message ticker'>
            {`Big hit by the ${this.props.home} in the ${this.state.inning}`} -{' '}
            {`Big hit by the ${this.props.home} in the ${this.state.inning}`}
          </div>
          <div className='ticker-gradient ticker-gradient-r'></div>

          <div className='score'>
            <div className='score-panels-wrapper'>
              <div className='score-board score-panel'>
                <div className='team team-left'>
                  <div className='team-logo'>
                    <img
                      src={`https://fanserv-media.s3.amazonaws.com/client/seatgeek/06-2016/assets/jerseys/${this.props.id_away}.png`}
                    />
                  </div>
                  <div className='team-name'>{this.props.away_short}</div>
                </div>
                <div className='team-center'>
                  <div className='game-score'>
                    <span className='score-home'>{this.state.points_home}</span>
                    <span className='score-dash'>-</span>
                    <span className='score-away'>{this.state.points_away}</span>
                  </div>

                  <div className='game-info'>
                    {this.state.inning_top_end} {this.state.inning}
                  </div>
                </div>
                <div className='team team-right'>
                  <div className='team-logo'>
                    <img
                      src={`https://fanserv-media.s3.amazonaws.com/client/seatgeek/06-2016/assets/jerseys/${this.props.id_home}.png`}
                    />
                  </div>
                  <div className='team-name'>{this.props.home_short}</div>
                </div>
              </div>
              <div className='box-score score-panel'>
                <table className='bs-table'>
                  <tbody>
                    <tr className='bs-header'>
                      <td></td>
                      {/* TODO: Figure out how to randomly add this
                      {% for inning, score in home.stats.score_by_inning %}
                      {% if inning == period %}
                      <td className="active">{{ inning }}</td>
                      {% else %}
                      <td className="">{{ inning }}</td>
                      {% endif %}
                      {% endfor %}
                      <td className="">R</td>
                      <td className="">H</td>
                      <td className="">E</td>
                    </tr>
                    <tr>
                      <td className="bs-team">{{ away.alias }}</td>
                      {% for inning, score in away.stats.score_by_inning %}
                      <td className="inning">{{ score }}</td>
                      {% endfor %}
                      <td className="aggregate">{{ away.points }}</td>
                      <td className="aggregate">{{ away.stats.hits }}</td>
                      <td className="aggregate">{{ away.stats.errors }}</td>
                    </tr>
                    <tr>
                      <td className="bs-team">{{ home.alias }}</td>
                      {% for inning, score in home.stats.score_by_inning %}
                      {% if score == "X" %}
                      <td className="inning">0</td>
                      {% else %}
                      <td className="inning">{{ score }}</td>
                      {% endif %}
                      {% endfor %}
                      <td className="aggregate">{{ home.points }}</td>
                      <td className="aggregate">{{ home.stats.hits }}</td>
                      <td className="aggregate">{{ home.stats.errors }}</td>
                    */}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </a>
      </div>
    );
  }
}

SeatGeekSize_300x250.propTypes = {
  home: PropTypes.string,
  away: PropTypes.string,
  home_short: PropTypes.string,
  away_short: PropTypes.string,
  id_home: PropTypes.number,
  id_away: PropTypes.number,
};

export default SeatGeekSize_300x250;
