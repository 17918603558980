import React from 'react';
import Select from 'react-select';
import find from 'lodash/find';
import filter from 'lodash/filter';

import BuildPlatform from './BuildPlatform';
import {
  leagues,
  game_states,
  platforms,
  leagueTeams,
  game_cons,
} from './PepsiVideo_data';
import './PepsiBuild.scss';

export default class PepsiBuild extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      league: null,
      home_side: null,
      home_short: null,
      home_teams: null,
      away_side: null,
      away_short: null,
      away_teams: null,
      game_short: 'nyy',
      game_state: null,
      game_condition: null,
      game_conditions_opts: null,
      platform: 'Desktop',
      condition: 'Home Win',
      home_pts: null,
      away_pts: null,
      quarter: null,
      fontsize: '1',
      clock: '0:00',
      home_1: null,
      home_2: null,
      home_3: null,
      home_4: null,
      home_5: null,
      home_6: null,
      home_7: null,
      home_8: null,
      home_9: null,
      away_1: null,
      away_2: null,
      away_3: null,
      away_4: null,
      away_5: null,
      away_6: null,
      away_7: null,
      away_8: null,
      away_9: null,
    };

    this.logGame = this.logGame.bind(this);
    this.logHomeTeam = this.logHomeTeam.bind(this);
    this.logAwayTeam = this.logAwayTeam.bind(this);
    this.logLeague = this.logLeague.bind(this);
    this.updateGameData = this.updateGameData.bind(this);
    this.logGameState = this.logGameState.bind(this);
    this.logGameCondition = this.logGameCondition.bind(this);
    this.logPlatform = this.logPlatform.bind(this);
  }

  logLeague(obj) {
    const leagueObj = find(leagueTeams, entry => entry.league === obj.value); //eslint-disable-line
    this.setState({
      league: obj.value,
      home_short: null,
      home_side: null,
      away_short: null,
      away_side: null,
      game_state: null,
      game_condition: null,
      home_1: null,
      home_2: null,
      home_3: null,
      home_4: null,
      home_5: null,
      home_6: null,
      home_7: null,
      home_8: null,
      home_9: null,
      away_1: null,
      away_2: null,
      away_3: null,
      away_4: null,
      away_5: null,
      away_6: null,
      away_7: null,
      away_8: null,
      away_9: null,
      home_pts: null,
      away_pts: null,
      status_first: null,
      status_second: null,
    });
    if (leagueObj.league === 'MLB') {
      const selectedTeams = filter(
        leagueObj.teams,
        team => team.pepsi_team === true
      );
      this.setState({ home_teams: selectedTeams });
    } else {
      this.setState({ home_teams: leagueObj.teams });
    }
    this.setState({ away_teams: leagueObj.teams });
  }

  removeOppositeTeam(side, team) {
    let currentTeams = find(
      leagueTeams,
      obj => obj.league === this.state.league
    );
    let filteredTeams = currentTeams.teams.filter(obj => obj.value !== team);
    if (side === 'away') {
      this.setState({ away_teams: filteredTeams });
    } else {
      this.setState({ home_teams: filteredTeams });
    }
  }

  logHomeTeam(obj) {
    this.updateGameData(obj.value);
    this.setState({
      home_side: obj.value,
      home_short: obj.short,
    });
    this.removeOppositeTeam('away', obj.value);
  }

  logAwayTeam(obj) {
    this.updateGameData(obj.value);
    this.setState({
      away_side: obj.value,
      away_short: obj.short,
    });
    this.removeOppositeTeam('home', obj.value);
  }

  logGameCondition(obj) {
    this.setState({ game_condition: obj.value });
    const statsObj = game_cons.filter(
      sport => sport.league === this.state.league
    );
    const stateStats = statsObj[0].game_states.filter(
      states => states.label === obj.value
    );

    Object.keys(stateStats[0].stats).map(stat => {
      let value = stateStats[0].stats[stat].toString();
      this.setState({ [stat]: value });
    });
  }

  logPlatform(obj) {
    this.setState({ platform: obj.value });
  }

  logGame(obj) {
    this.setState({ game: obj.value });
    this.setState({ game_short: this.team_names[obj.value] });
  }

  updateGameData(newState) {
    const obj = game_cons.filter(sport => sport.league === this.state.league);
    const game_states = obj[0].game_states.filter(
      state => state.status === newState
    );
    this.setState({ game_conditions_opts: game_states });
  }

  /**
   * This glues the relationships between Game State and Game Conditions
   */
  logGameState(obj) {
    this.setState({
      game_state: obj.value,
      home_1: null,
      home_2: null,
      home_3: null,
      home_4: null,
      home_5: null,
      home_6: null,
      home_7: null,
      home_8: null,
      home_9: null,
      away_1: null,
      away_2: null,
      away_3: null,
      away_4: null,
      away_5: null,
      away_6: null,
      away_7: null,
      away_8: null,
      away_9: null,
      home_ot: null,
      away_ot: null,
      home_pts: null,
      away_pts: null,
      status_first: null,
      status_second: null,
    });
    this.updateGameData(obj.value);
  }

  render() {
    return (
      <div className=''>
        <div className='Pepsi_Video'>
          <div className='CampaignBuilder'>
            <ul className='CampaignBuilder-list'>
              <li className='CampaignBuilder-item CampaignBuilder-select'>
                <label className='CampaignBuilder-label'>League</label>
                <Select
                  name='PepsiBuild-league'
                  options={leagues}
                  onChange={this.logLeague}
                  clearable={false}
                />
              </li>

              <li className='CampaignBuilder-item CampaignBuilder-select'>
                <label className='CampaignBuilder-label'>Home Team</label>
                <Select
                  name='PepsiBuild-home'
                  options={this.state.home_teams}
                  disabled={false}
                  onChange={this.logHomeTeam}
                  clearable={false}
                />
              </li>

              <li className='CampaignBuilder-item CampaignBuilder-select'>
                <label className='CampaignBuilder-label'>Away Team</label>
                <Select
                  name='PepsiBuild-away'
                  options={this.state.away_teams}
                  disabled={false}
                  onChange={this.logAwayTeam}
                  clearable={false}
                />
              </li>
            </ul>

            <ul className='CampaignBuilder-list'>
              <li className='CampaignBuilder-item CampaignBuilder-select'>
                <label className='CampaignBuilder-label'>Game State</label>
                <Select
                  name='PepsiBuild-game_state'
                  options={game_states}
                  onChange={this.logGameState}
                  clearable={false}
                />
              </li>

              <li className='CampaignBuilder-item CampaignBuilder-select'>
                <label className='CampaignBuilder-label'>Game Conditions</label>
                <Select
                  name='PepsiBuild-game_condition'
                  options={this.state.game_conditions_opts}
                  onChange={this.logGameCondition}
                  clearable={false}
                />
              </li>

              <li className='CampaignBuilder-item CampaignBuilder-select'>
                <label className='CampaignBuilder-label'>Platform</label>
                <Select
                  name='PepsiBuild-platform'
                  value={platforms.filter(
                    ({ value }) => value === this.state.platform
                  )}
                  options={platforms}
                  onChange={this.logPlatform}
                  clearable={false}
                  isDisabled
                />
              </li>
            </ul>
          </div>

          <BuildPlatform {...this.state} />
        </div>
      </div>
    );
  }
}
