import React, { Component } from 'react';
import './CampaignAlpha_300x250_wendys.scss';
import PropTypes from 'prop-types';

export default class CampaignAlpha_300x250_wendys extends Component {
  constructor() {
    super();

    this.state = {
      price: this.randomNum(40, 50),
    };
  }

  randomNum(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  render() {
    let away_logo = null;
    if (this.props.away_team_short === null) {
      away_logo = <div></div>;
    } else {
      away_logo = (
        <img
          src={`https://fanserv-media.s3.amazonaws.com/sales_tool/logos/${this.props.away_team_short}.png`}
        />
      );
    }

    let adCopy = null;
    if (
      this.props.game_state === 'Pregame' &&
      this.props.game_condition === 'Countdown'
    ) {
      adCopy = (
        <div className='adCopy pregame'>
          <div className='topLine tu'>Free Frosty's</div>
          <div className='middleLine tu'>
            When the <span className='red'>WILD</span> score 4!
          </div>
          <div className='bottomLine'>
            Game Time in: <span className='red'>4</span> hrs{' '}
            <span className='red'>32</span> min
          </div>
        </div>
      );
    } else if (this.props.game_state === 'Live') {
      if (this.props.game_condition === 'Wild 1 Goal') {
        adCopy = (
          <div className='adCopy live'>
            <div className='topLine tu'>Wild Score!</div>
            <div className='middleLine tu'>
              <span className='red'>3</span> More and You Win!
            </div>
          </div>
        );
      } else if (this.props.game_condition === 'Wild 2 Goals') {
        adCopy = (
          <div className='adCopy live'>
            <div className='topLine tu'>
              Wild Score <span className='red'>2</span>!
            </div>
            <div className='middleLine tu'>2 More and You Win!</div>
          </div>
        );
      } else if (this.props.game_condition === 'Wild 3 Goals') {
        adCopy = (
          <div className='adCopy live'>
            <div className='topLine tu'>
              Wild Score <span className='red'>3</span>!
            </div>
            <div className='middleLine tu'>1 More and You Win!</div>
          </div>
        );
      } else if (this.props.game_condition === 'Wild 4 Goals') {
        adCopy = (
          <div className='adCopy live freeFrosty'>
            <div className='topLine tu'>
              Get Your <span className='red'>Free</span> Frosty
            </div>
            <div className='middleLine tu'>
              Use Coupon Code: <span className='code'>Wendy4</span>
            </div>
            <div className='bottomLine'>Valid At Twin Cities Locations</div>
          </div>
        );
      }
    } else if (this.props.game_state === 'Postgame') {
      if (this.props.game_condition === 'Home Win') {
        adCopy = (
          <div className='adCopy freeFrosty'>
            <div className='topLine tu'>
              <span className='red'>Wild</span> Win!{' '}
              <span className='red'>You</span> Win!
            </div>
            <div className='middleLine tu'>
              Use Coupon Code: <span className='code'>Wendy4</span>
            </div>
            <div className='bottomLine'>Valid At Twin Cities Locations</div>
          </div>
        );
      } else if (this.props.game_condition === 'Home Loss') {
        adCopy = (
          <div className='adCopy freeFrosty'>
            <div className='topLine tu'>No free Frosty...</div>
            <div className='middleLine onerem'>
              ...but there’s always next game!
            </div>
            <div className='bottomLine'>
              Next Game in: <span className='red'>2</span> days{' '}
              <span className='red'>18</span> hours
            </div>
          </div>
        );
      }
    }

    return (
      <div className='Wendys_300x250-container'>
        <div className='gameInfo'>
          <div className='away-logo team-logo'>
            <img src='https://fanserv-media.s3.amazonaws.com/sales_tool/logos/wild.png' />
          </div>
          <div className='gameInfo-middle'>
            <div className='gameInfo-score'>
              <span>{this.props.home_goals}</span> -{' '}
              <span>{this.props.aways_goals}</span>
            </div>
            <div className='gameInfo-time'>
              <span className='spacer'>{this.props.clock}</span>{' '}
              <span className='spacer'>{this.props.period}</span>
            </div>
          </div>
          <div className='home-logo team-logo'>{away_logo}</div>
        </div>

        {adCopy}
      </div>
    );
  }
}

CampaignAlpha_300x250_wendys.propTypes = {
  game: PropTypes.string,
  game_state: PropTypes.string,
  game_condition: PropTypes.string,
  home_team: PropTypes.string,
  home_team_short: PropTypes.string,
  away_team: PropTypes.string,
  away_team_short: PropTypes.string,
  home_goals: PropTypes.string,
  aways_goals: PropTypes.string,
  clock: PropTypes.string,
  period: PropTypes.string,
};
