import React from 'react';
import Select from 'react-select';
import find from 'lodash/find';
import filter from 'lodash/filter';

import BuildPlatform from './BuildPlatform';
import {
  leagues,
  game_states,
  platforms,
  leagueTeams,
  game_cons,
} from './PepsiVideo_data';
import './PepsiBuild.scss';
// import 'react-select/dist/react-select.css';

export default class CowboysBuild extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      league: 'NFL',
      home_side: 'Dallas Cowboys',
      home_short: 'DAL',
      home_teams: [
        { value: 'Dallas Cowboys', label: 'Dallas Cowboys', short: 'DAL' },
      ],
      away_side: 'Washington Redskins',
      away_short: 'WAS',
      away_teams: [
        {
          value: 'Washington Redskins',
          label: 'Washington Redskins',
          short: 'WAS',
        },
      ],
      game_short: 'nyy',
      game_state: 'Postgame',
      game_states: [{ value: 'Postgame', label: 'Postgame' }],
      game_condition: 'Home Win',
      game_conditions_opts: [{ value: 'Home Win', label: 'Home Win' }],
      platform: 'Desktop',
      condition: 'Home Win',
      status_first: 'Final',
      status_second: '',
      home_pts: 27,
      away_pts: 23,
      quarter: 4,
      clock: 'Final',
      home_1: 10,
      home_2: 3,
      home_3: 7,
      home_4: 7,
      home_ot: 0,
      away_1: 0,
      away_2: 10,
      away_3: 13,
      away_4: 0,
      away_ot: 0,
    };

    this.logGame = this.logGame.bind(this);
    this.logHomeTeam = this.logHomeTeam.bind(this);
    this.logAwayTeam = this.logAwayTeam.bind(this);
    this.logLeague = this.logLeague.bind(this);
    this.updateGameData = this.updateGameData.bind(this);
    this.logGameState = this.logGameState.bind(this);
    this.logGameCondition = this.logGameCondition.bind(this);
    this.logPlatform = this.logPlatform.bind(this);
  }

  logLeague(obj) {
    const leagueObj = find(leagueTeams, entry => entry.league === obj.value); //eslint-disable-line
    this.setState({});
    if (leagueObj.league === 'MLB') {
      const selectedTeams = filter(
        leagueObj.teams,
        team => team.pepsi_team === true
      );
      this.setState({ home_teams: selectedTeams });
    } else {
      this.setState({ home_teams: leagueObj.teams });
    }
    this.setState({ away_teams: leagueObj.teams });
  }

  removeOppositeTeam(side, team) {
    let currentTeams = find(
      leagueTeams,
      obj => obj.league === this.state.league
    );
    let filteredTeams = currentTeams.teams.filter(obj => obj.value !== team);
    if (side === 'away') {
      this.setState({ away_teams: filteredTeams });
    } else {
      this.setState({ home_teams: filteredTeams });
    }
  }

  logHomeTeam(obj) {
    this.updateGameData(obj.value);
    this.setState({
      home_side: obj.value,
      home_short: obj.short,
    });
    this.removeOppositeTeam('away', obj.value);
  }

  logAwayTeam(obj) {
    this.updateGameData(obj.value);
    this.setState({
      away_side: obj.value,
      away_short: obj.short,
    });
    this.removeOppositeTeam('home', obj.value);
  }

  logGameCondition(obj) {
    this.setState({ game_condition: obj.value });
    const statsObj = game_cons.filter(
      sport => sport.league === this.state.league
    );
    const stateStats = statsObj[0].game_states.filter(
      states => states.label === obj.value
    );

    Object.keys(stateStats[0].stats).map(stat => {
      let value = stateStats[0].stats[stat].toString();
      this.setState({ [stat]: value });
    });
  }

  logPlatform(obj) {
    this.setState({ platform: obj.value });
  }

  logGame(obj) {
    this.setState({ game: obj.value });
    this.setState({ game_short: this.team_names[obj.value] });
  }

  updateGameData(newState) {
    const obj = game_cons.filter(sport => sport.league === this.state.league);
    const game_states = obj[0].game_states.filter(
      state => state.status === newState
    );
    this.setState({ game_conditions_opts: game_states });
  }

  /**
   * This glues the relationships between Game State and Game Conditions
   */
  logGameState(obj) {
    this.updateGameData(obj.value);
  }

  render() {
    return (
      <div className=''>
        <div className='Pepsi_Video'>
          <div className='CampaignBuilder'>
            <ul className='CampaignBuilder-list'>
              <li className='CampaignBuilder-item CampaignBuilder-select'>
                <label className='CampaignBuilder-label'>League</label>
                <Select
                  name='PepsiBuild-league'
                  value={leagues.filter(
                    ({ value }) => value === this.state.league
                  )}
                  options={leagues}
                  isDisabled
                  onChange={this.logLeague}
                  clearable={false}
                />
              </li>

              <li className='CampaignBuilder-item CampaignBuilder-select'>
                <label className='CampaignBuilder-label'>Home Team</label>
                <Select
                  name='PepsiBuild-home'
                  value={this.state.home_teams.filter(
                    ({ value }) => value === this.state.home_side
                  )}
                  options={this.state.home_teams}
                  isDisabled
                  onChange={this.logHomeTeam}
                  clearable={false}
                />
              </li>

              <li className='CampaignBuilder-item CampaignBuilder-select'>
                <label className='CampaignBuilder-label'>Away Team</label>
                <Select
                  name='PepsiBuild-away'
                  value={this.state.away_teams.filter(
                    ({ value }) => value === this.state.away_side
                  )}
                  options={this.state.away_teams}
                  isDisabled
                  onChange={this.logAwayTeam}
                  clearable={false}
                />
              </li>
            </ul>

            <ul className='CampaignBuilder-list'>
              <li className='CampaignBuilder-item CampaignBuilder-select'>
                <label className='CampaignBuilder-label'>Game State</label>
                <Select
                  name='PepsiBuild-game_state'
                  value={game_states.filter(
                    ({ value }) => value === this.state.game_state
                  )}
                  options={this.state.game_states}
                  onChange={this.logGameState}
                  clearable={false}
                  isDisabled
                />
              </li>

              <li className='CampaignBuilder-item CampaignBuilder-select'>
                <label className='CampaignBuilder-label'>Game Conditions</label>
                <Select
                  name='PepsiBuild-game_condition'
                  value={this.state.game_conditions_opts.filter(
                    ({ value }) => value === this.state.game_condition
                  )}
                  options={this.state.game_conditions_opts}
                  onChange={this.logGameCondition}
                  clearable={false}
                />
              </li>

              <li className='CampaignBuilder-item CampaignBuilder-select'>
                <label className='CampaignBuilder-label'>Platform</label>
                <Select
                  name='PepsiBuild-platform'
                  value={platforms.filter(
                    ({ value }) => value === this.state.platform
                  )}
                  options={platforms}
                  onChange={this.logPlatform}
                  clearable={false}
                  isDisabled
                />
              </li>
            </ul>
          </div>

          <BuildPlatform {...this.state} />
        </div>
      </div>
    );
  }
}
