import React from 'react';
import PropTypes from 'prop-types';

export default class PepsiVideo extends React.Component {
  constructor() {
    super();
    this.state = {
      commercialDone: false,
      league: null,
    };

    this.videoEnd = this.videoEnd.bind(this);
  }

  componentDidMount() {
    this.setState({ league: 'mlb' });
  }

  componentWillReceiveProps() {
    this.setState({ league: this.props.league });
  }

  componentDidUpdate() {
    this.props.league !== null && this.updateVideo();
  }

  updateVideo() {
    if (this.state.commercialDone) {
      this.vidPlayer.pause();
      this.vidMp4.src = `/videos/${this.props.league.toLowerCase()}-highlights.mp4`;
      this.vidOgg.src = `/videos/${this.props.league.toLowerCase()}-highlights.ovg`;
      this.vidWebm.src = `/videos/${this.props.league.toLowerCase()}-highlights.mp4`;
      this.vidPlayer.load();
      setTimeout(() => {
        this.vidPlayer.play();
      }, 150);
    }
  }

  videoEnd() {
    this.setState({ commercialDone: true });
    this.vidPlayer.pause();
    this.vidMp4.src = `/videos/${this.state.league}-highlights.mp4`;
    this.vidOgg.src = `/videos/${this.state.league}-highlights.ovg`;
    this.vidWebm.src = `/videos/${this.state.league}-highlights.mp4`;
    this.vidPlayer.removeEventListener('onended', this.videoEnd);
    this.vidPlayer.load();
    setTimeout(() => {
      this.vidPlayer.play();
    }, 150);
  }

  render() {
    return (
      <video
        autoPlay
        muted
        playsInline
        ref={vidPlayer => (this.vidPlayer = vidPlayer)}
        onEnded={this.videoEnd}
      >
        <source
          src='/videos/pepsi.mp4'
          type='video/mp4'
          ref={vidMp4 => (this.vidMp4 = vidMp4)}
        />
        <source
          src='/videos/pepsi.ogv'
          type='video/ogg'
          ref={vidOgg => (this.vidOgg = vidOgg)}
        />
        <source
          src='/videos/pepsi.webm'
          type='video/webm'
          ref={vidWebm => (this.vidWebm = vidWebm)}
        />
      </video>
    );
  }
}

PepsiVideo.propTypes = {
  league: PropTypes.string,
};
