import React from 'react';
import './PistonsSize_728x90.scss' ;

export default class PistonsSize_728x90 extends React.Component {
  render() {
    return (
      <div className="PistonsSize_728x90">
        <a href="#" className="ad-container loaded">
          <div className="fox-logo"></div>
          <div className="game-copy">
            <div className="copy-double line1">1-Point</div>
            <div className="copy-double line2">Game</div>
          </div>
          <div className="button-scoreboard">
            <div className="scoreboard-wrapper">
              <div className="scoreboard-frame scoreboard-two">
                <table>
                  <thead>
                    <tr>
                      <td></td>
                      <td>PTS</td>
                      <td>FG%</td>
                      <td>3 PT%</td>
                      <td>FT %</td>
                      <td>RBS</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="table-team">DET</td>
                      <td>51</td>
                      <td>43.0</td>
                      <td>46.2</td>
                      <td>80.2</td>
                      <td>55</td>
                    </tr>
                    <tr>
                      <td className="table-team">LAL</td>
                      <td>50</td>
                      <td>52.3</td>
                      <td>47.8</td>
                      <td>78.4</td>
                      <td>51</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            <div className="scoreboard-frame scoreboard-one">
              <div className="team-box team-away">
                <div>
                  <img src="https://fanserv-media.s3.amazonaws.com/client/fox_sports/pistons-tune-in-v2/300x250/dist/images/logo-det.png" width="75" height="46" className="team-logo" />
                </div>
              </div>
              <div className="score-box score-away">
                <div className="points points-away">50</div>
                <div className="fouls-meter fouls-away"><span className="to-abrev">TO</span> 4</div>
              </div>
              <div className="scoreboard-clock">
                <div className="clock-wrapper">
                  <div className="scoreboard-qt">Q2</div>
                  <div className="scoreboard-time">12:09</div>
                </div>
              </div>
              <div className="score-box score-home">
                <div className="points points-home">51</div>
                <div className="fouls-meter fouls-home"><span className="to-abrev">TO</span> 4</div>
              </div>
              <div className="team-box team-home">
                <div>
                  <img src="https://fanserv-media.s3.amazonaws.com/client/fox_sports/pistons-tune-in-v2/300x250/dist/images/logo-lal.png" width="75" height="46" className="team-logo" />
                </div>
              </div>
            </div>
          </div>
        <div className="tune-in-button">
          <span className="detBlue">Ch. 737</span> <span className="tune-in-icon icon-apple"></span> <span className="tune-in-icon icon-android"></span> TUNE IN NOW!
        </div>
      </div>
          <div className="live">Live</div>
            <div className="billboard-content">
              <div className="billboard-player">
                <img src="https://fanserv-media.s3.amazonaws.com/client/fox_sports/pistons-tune-in-v2/728x90/dist/images/player.png" className="player-img" />
              </div>
          </div>
        </a>
      </div>
    )
  }
}
