import React from 'react';
import PropTypes from 'prop-types';

const NFLBoxScore = props => (
  <div className='boxScore'>
    <table>
      <thead>
        <tr>
          <th colSpan='2'></th>
          <th>1</th>
          <th>2</th>
          <th>3</th>
          <th>4</th>
          <th>OT</th>
          <th colSpan='2'>TOTAL</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className='teamAbr' colSpan='2'>
            {props.away_short}
          </td>
          <td>{props.away_1}</td>
          <td>{props.away_2}</td>
          <td>{props.away_3}</td>
          <td>{props.away_4}</td>
          <td>{props.away_ot}</td>
          <td className='totalBig' colSpan='2'>
            {props.away_pts}
          </td>
        </tr>
        <tr>
          <td className='teamAbr' colSpan='2'>
            {props.home_short}
          </td>
          <td>{props.home_1}</td>
          <td>{props.home_2}</td>
          <td>{props.home_3}</td>
          <td>{props.home_4}</td>
          <td>{props.home_ot}</td>
          <td className='totalBig' colSpan='2'>
            {props.home_pts}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default NFLBoxScore;

NFLBoxScore.propTypes = {
  away_short: PropTypes.string,
  home_short: PropTypes.string,
  away_pts: PropTypes.string,
  home_pts: PropTypes.string,
  away_1: PropTypes.string,
  away_2: PropTypes.string,
  away_3: PropTypes.string,
  away_4: PropTypes.string,
  home_1: PropTypes.string,
  home_2: PropTypes.string,
  home_3: PropTypes.string,
  home_4: PropTypes.string,
  away_ot: PropTypes.string,
  home_ot: PropTypes.string,
};
