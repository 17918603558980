import React from 'react';

import CanucksLive_1 from './CanucksLive_1';
import CanucksLive_2 from './CanucksLive_2';
import CanucksLive_3 from './CanucksLive_3';
import CanucksLive_4 from './CanucksLive_4';
import './CanucksLive_All.scss' ;

export default class CanucksLive_All extends React.Component {
  render() {
    return (
      <div className="CanucksLive_All">
       <CanucksLive_1 key="1" />
       <CanucksLive_2 key="2" />
       <CanucksLive_3 key="3" />
       <CanucksLive_4 key="4" />
      </div>
    )
  }
}
