import React from 'react';
import PGA_Ad_Live from './PGA_Ad_Live';
import Isvg from 'react-inlinesvg';
import './PGA_Live.scss';

export default class PGA_Live extends React.Component {
  render() {
    return (
      <div className="PGA_Live">
        <div className="adContainer">
          <PGA_Ad_Live />
        </div>
        <div className="phone">
          <Isvg src="/mobile.svg" />
        </div>
      </div>
    )
  }
}
