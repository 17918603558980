import React from 'react';

import PistonsBuild from './ads/pistons/PistonsBuild';
import PepsiBuild from './ads/pepsi/PepsiBuild';
import { useParams } from 'react-router';

const AdsLive = () => {
  const { adName } = useParams();

  let ads = null;
  if (adName === 'pistons') {
    ads = <PistonsBuild key={'PistonsLive_All'} />;
  } else if (adName === 'pepsi') {
    ads = <PepsiBuild key={'PepsiBuild_All'} />;
  }

  return <div className='AdsWrapper-300x250'>{ads}</div>;
};
export default AdsLive;
