import React, { Component } from 'react';
import './CampaignAlpha_300x250_replybuy.scss';
import PropTypes from 'prop-types';

export default class CampaignAlpha_300x250_replybuy extends Component {
  constructor() {
    super();

    this.state = {
      price: this.randomNum(40, 50),
    };
  }

  randomNum(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  render() {
    // Game
    let game = null;
    if (this.props.game === null) {
      game = <div></div>;
    } else {
      if (this.props.game === 'Anaheim Ducks') {
        game = <div className='away_team'>Ducks</div>;
      } else if (this.props.game === 'Detroit Red Wings') {
        game = <div className='away_team'>Red Wings</div>;
      }
    }

    // Background Player
    let bgPlayer = null;
    if (this.props.game === null) {
      bgPlayer = <div></div>;
    } else {
      if (this.props.game === 'Anaheim Ducks') {
        bgPlayer = <div className='player-background player ducks'></div>;
      } else if (this.props.game === 'Detroit Red Wings') {
        bgPlayer = <div className='player-background player wings'></div>;
      }
    }

    // Top Banner
    let topBanner = null;
    if (this.props.game_state === null) {
      topBanner = <div></div>;
    } else {
      if (this.props.game_state === 'Pregame') {
        topBanner = (
          <div className='top-banner'>
            <div className='frame-wrapper'>
              <div className='frame countdown'>
                The Puck Drops In: <span className='white'>2</span> hours{' '}
                <span className='white'>28</span> Minutes
              </div>
              <div className='frame stillTime'>
                There is still time to get great seats!
              </div>
            </div>
          </div>
        );
      } else if (this.props.game_state === 'Live') {
        topBanner = (
          <div className='top-banner'>
            <div className='frame stillTime'>
              There is still time to get great seats!
            </div>
          </div>
        );
      } else if (this.props.game_state === 'Postgame') {
        topBanner = (
          <div className='top-banner'>
            <div className='frame stillTime'>
              Next Game vs. Jets In <span className='white'>3</span> Days{' '}
              <span className='white'>10</span> Hours
            </div>
          </div>
        );
      }
    }

    // Away logo
    let away_logo = null;
    if (this.props.away_team_short === null) {
      away_logo = <div></div>;
    } else {
      away_logo = (
        <img
          src={`https://fanserv-media.s3.amazonaws.com/sales_tool/logos/${this.props.away_team_short}.png`}
        />
      );
    }

    // away team abrevation
    let away_team_abr = null;
    if (this.props.away_team_short === 'ducks') {
      away_team_abr = 'ANA';
    } else if (this.props.away_team_short === 'red_wings') {
      away_team_abr = 'DET';
    }

    // Dynamic Price & or Score
    let priceBlock = null;
    if (this.props.game_state === null) {
      priceBlock = <div></div>;
    } else {
      if (this.props.game_state === 'Pregame') {
        priceBlock = (
          <div className='middle-box-container'>
            <div className='middlebox-wrapper'>
              <div className='middle-box-tickets'>
                <div className='tickets-from'>Tickets From</div>
                <div className='tickets-price'>
                  <div className='dollar-sign price-info'>$</div>
                  <div className='dollar-ammount price-info'>
                    {this.state.price}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else if (this.props.game_state === 'Live') {
        priceBlock = (
          <div className='middle-box-container'>
            <div className='middlebox-wrapper middlebox-wrapper-anim'>
              <div className='middle-box-tickets frame'>
                <div className='tickets-from'>Tickets From</div>
                <div className='tickets-price'>
                  <div className='dollar-sign price-info'>$</div>
                  <div className='dollar-ammount price-info'>
                    {this.state.price}
                  </div>
                </div>
              </div>
              <div className='middle-box-scores frame'>
                <div className='score-home score-team'>
                  <div className='logo'>
                    <img src='https://fanserv-media.s3.amazonaws.com/sales_tool/wild-logo_100x100.png' />
                  </div>
                  <div className='team-name'>Min</div>
                </div>

                <div className='score-middle'>
                  <div className='score'>
                    {this.props.home_goals} - {this.props.aways_goals}
                  </div>
                  <div className='score-period'>{this.props.period}</div>
                </div>

                <div className='score-away score-team'>
                  <div className='logo'>{away_logo}</div>
                  <div className='team-name'>{away_team_abr}</div>
                </div>
              </div>
            </div>
          </div>
        );
      } else if (this.props.game_state === 'Postgame') {
        priceBlock = (
          <div className='middle-box-container'>
            <div className='middlebox-wrapper'>
              <div className='middle-box-tickets'>
                <div className='tickets-from'>Premium Seats</div>
                <div className='tickets-from'>Available</div>
              </div>
            </div>
          </div>
        );
      }
    }

    // Ticket Button
    let ticketBtn = null;
    if (this.props.game_state === null) {
      ticketBtn = <div></div>;
    } else {
      if (this.props.game_state === 'Pregame') {
        ticketBtn = <div className='ticketsBtn'>Find Tickets</div>;
      } else if (this.props.game_state === 'Live') {
        ticketBtn = <div className='ticketsBtn'>Find Tickets</div>;
      } else if (this.props.game_state === 'Postgame') {
        ticketBtn = <div className='ticketsBtn'>Sign Up Now</div>;
      }
    }

    return (
      <div className='ReplyBuy_300x250-container'>
        {topBanner}

        <div className='teams'>
          <div className='home_team'>
            Wild <span className='vs'>vs</span>
          </div>
          {game}
        </div>

        <div className='players'>
          <div className='player-foreground player'></div>
          {bgPlayer}
        </div>

        {priceBlock}

        {ticketBtn}

        <div className='ReplyBuy-logo-container'>
          <div className='ReplyBuy-logo-poweredBy'>Powered By</div>
          <div className='ReplyBuy-logo-graphic'></div>
        </div>
      </div>
    );
  }
}

CampaignAlpha_300x250_replybuy.propTypes = {
  game: PropTypes.string,
  game_state: PropTypes.string,
  game_condition: PropTypes.string,
  home_team: PropTypes.string,
  home_team_short: PropTypes.string,
  away_team: PropTypes.string,
  away_team_short: PropTypes.string,
  home_goals: PropTypes.string,
  aways_goals: PropTypes.string,
  clock: PropTypes.string,
  period: PropTypes.string,
};
