import React from 'react';
import './CanucksLive_3.scss';

export default class CanucksLive_3 extends React.Component {
  render() {
    return (
      <div className="canucksLive_3">
        <a id="ad-container" href="#" target="_blank" className="container ad-size-300x250 loaded">
          <div className="adContent">
            <div className="copy-content">
              <div className="game-info">
                <span className="team-top">Canucks</span><br />
                <span className="team-bottom"><span className="vs">VS</span> Flames</span><br />
                <span className="date">Fri.Jan.06</span>
              </div>
              <div className="ticket-info">
                <div className="ticket-price">
                  <span className="hide spinner">
                    <img src="https://fanserv-media.s3.amazonaws.com/client/canucks/300x250/game_2/dist/images/shared/spinner.svg" />
                  </span>
                  <span className=" ticket-from">
                    <span className="ticket-label">Tickets From</span>
                    <span className="dollar-sign">$</span>
                    <span className="dollar-price">34</span>
                  </span>
                  <span className="available-msg hide">
                    Limited Tickets<br /> Available
                  </span>
                </div>
              </div>
            </div>
            <div className="type-texture"></div>
            <div className="cta-button">Find Tickets</div>
            <div className="motion-content">
              <div className="player-frame player-background"></div>
              <div className="player-frame player-foreground"></div>
              <div className="texture"></div>
            </div>
          </div>
        </a>
      </div>
    )
  }
}
