import React from 'react';
import PGA_Ad_Highlights from './PGA_Ad_Highlights';
import Isvg from 'react-inlinesvg';
import './PGA_highlights.scss';

export default class PGA_highlights extends React.Component {
  render() {
    return (
      <div className="PGA_Highlights">
        <div className="adContainer">
          <PGA_Ad_Highlights />
        </div>
        <div className="browser">
          <Isvg src="/browser2.svg" />
        </div>
      </div>

    )
  }
}
