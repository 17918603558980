import React, { Component } from 'react';
import './CampaignAlpha_300x250_cocacola.scss';
import PropTypes from 'prop-types';

export default class CampaignAlpha_300x250_cocacola extends Component {
  constructor() {
    super();

    this.state = {
      price: this.randomNum(40, 50),
    };

    this.randomNum = this.randomNum.bind(this);
    this.removeUnderscore = this.removeUnderscore.bind(this);
  }

  randomNum(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  removeUnderscore(s) {
    return s.replace(/_/g, ' ');
  }

  render() {
    // Countdown Area
    let topBanner = null;
    if (this.props.game_state === 'Pregame') {
      topBanner = (
        <div className='topBanner-bottom'>
          <span className='red'>10</span> Hours <span className='red'>3</span>{' '}
          Minutes
        </div>
      );
    } else if (this.props.game_state === 'Live') {
      topBanner = <div className='topBanner-bottom'>Live</div>;
    } else if (this.props.game_state === null) {
      topBanner = <div className='topBanner-bottom'></div>;
    } else if (this.props.game_state === 'Postgame') {
      topBanner = <div className='topBanner-bottom final'>Final</div>;
    }

    // Clock Score
    let clockScore = null;
    if (this.props.home_goals === null && this.props.aways_goals === null) {
      clockScore = <div className='clock-score'>0 - 0</div>;
    } else {
      clockScore = (
        <div className='clock-score'>
          {this.props.home_goals} - {this.props.aways_goals}
        </div>
      );
    }

    // Clock Time
    let clockTime = null;
    if (this.props.home_goals === null && this.props.aways_goals === null) {
      clockTime = <div></div>;
    } else if (this.props.game_state === 'Pregame') {
      clockTime = <div className='clock-time'>7:30pm</div>;
    } else if (this.props.game_state === 'Live') {
      clockTime = (
        <div className='clock-time'>
          <span className='split'>{this.props.clock}</span>{' '}
          <span className='split'>{this.props.period}</span>
        </div>
      );
    } else {
      clockTime = <div className='clock-time'></div>;
    }

    // Ad Copy
    let adCopy = null;
    if (this.props.game_state === 'Pregame') {
      adCopy = (
        <p>
          Grab a Coke and get ready to watch the Wild take on the{' '}
          <span className='capitalize'>
            {this.removeUnderscore(this.props.away_team_short)}
          </span>{' '}
          from the Xcel Energy Center.
        </p>
      );
    } else if (this.props.game_state === 'Live') {
      adCopy = (
        <p className='liveBigText'>
          Wild up {this.props.home_goals} - {this.props.aways_goals} with <br />{' '}
          {this.props.clock} to go in the {this.props.period}
        </p>
      );
    } else if (
      this.props.game_state === 'Postgame' &&
      parseInt(this.props.home_goals) > parseInt(this.props.aways_goals)
    ) {
      adCopy = (
        <p>
          Wild beat the{' '}
          <span className='capitalize'>
            {this.removeUnderscore(this.props.away_team_short)}
          </span>{' '}
          at the Xcel Energy Center. Next up for the Wild: <br />
          Anaheim in <span className='red'>2</span> Days{' '}
          <span className='red'>8</span> Hours
        </p>
      );
    } else if (
      this.props.game_state === 'Postgame' &&
      parseInt(this.props.home_goals) < parseInt(this.props.aways_goals)
    ) {
      adCopy = (
        <p>
          Wild fall to the{' '}
          <span className='capitalize'>
            {this.removeUnderscore(this.props.away_team_short)}
          </span>{' '}
          at the Xcel Energy Center. Next up for the Wild: <br />
          Anaheim in <span className='red'>2</span> Days{' '}
          <span className='red'>8</span> Hours
        </p>
      );
    }

    // Game Stats
    let gameStats = null;
    if (this.props.game_condition === 'Wild 1 Goal') {
      gameStats = (
        <table className='box'>
          <thead>
            <tr>
              <th></th>
              <th>SOG</th>
              <th>FO%</th>
              <th>PP</th>
              <th>PIM</th>
              <th>HITS</th>
              <th>BLKS</th>
              <th>GVA</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='smallLogo'>
                <img
                  src='https://fanserv-media.s3.amazonaws.com/sales_tool/logos/wild.png'
                  alt=''
                />
              </td>
              <td>11</td>
              <td>58%</td>
              <td>1/1</td>
              <td>0</td>
              <td>4</td>
              <td>3</td>
              <td>1</td>
            </tr>
            <tr>
              <td className='smallLogo'>
                <img
                  src={`https://fanserv-media.s3.amazonaws.com/sales_tool/logos/${this.props.away_team_short}.png`}
                />
              </td>
              <td>9</td>
              <td>42%</td>
              <td>0/1</td>
              <td>4</td>
              <td>2</td>
              <td>1</td>
              <td>1</td>
            </tr>
          </tbody>
        </table>
      );
    } else if (this.props.game_condition === 'Wild 2 Goals') {
      gameStats = (
        <table className='box'>
          <thead>
            <tr>
              <th></th>
              <th>SOG</th>
              <th>FO%</th>
              <th>PP</th>
              <th>PIM</th>
              <th>HITS</th>
              <th>BLKS</th>
              <th>GVA</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='smallLogo'>
                <img
                  src='https://fanserv-media.s3.amazonaws.com/sales_tool/logos/wild.png'
                  alt=''
                />
              </td>
              <td>14</td>
              <td>56%</td>
              <td>1/2</td>
              <td>2</td>
              <td>5</td>
              <td>4</td>
              <td>2</td>
            </tr>
            <tr>
              <td className='smallLogo'>
                <img
                  src={`https://fanserv-media.s3.amazonaws.com/sales_tool/logos/${this.props.away_team_short}.png`}
                />
              </td>
              <td>11</td>
              <td>44%</td>
              <td>0/1</td>
              <td>6</td>
              <td>4</td>
              <td>3</td>
              <td>1</td>
            </tr>
          </tbody>
        </table>
      );
    } else if (this.props.game_condition === 'Wild 3 Goals') {
      gameStats = (
        <table className='box'>
          <thead>
            <tr>
              <th></th>
              <th>SOG</th>
              <th>FO%</th>
              <th>PP</th>
              <th>PIM</th>
              <th>HITS</th>
              <th>BLKS</th>
              <th>GVA</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='smallLogo'>
                <img
                  src='https://fanserv-media.s3.amazonaws.com/sales_tool/logos/wild.png'
                  alt=''
                />
              </td>
              <td>22</td>
              <td>54%</td>
              <td>2/4</td>
              <td>4</td>
              <td>8</td>
              <td>6</td>
              <td>3</td>
            </tr>
            <tr>
              <td className='smallLogo'>
                <img
                  src={`https://fanserv-media.s3.amazonaws.com/sales_tool/logos/${this.props.away_team_short}.png`}
                />
              </td>
              <td>16</td>
              <td>46%</td>
              <td>1/2</td>
              <td>10</td>
              <td>7</td>
              <td>5</td>
              <td>2</td>
            </tr>
          </tbody>
        </table>
      );
    } else if (this.props.game_condition === 'Wild 4 Goals') {
      gameStats = (
        <table className='box'>
          <thead>
            <tr>
              <th></th>
              <th>SOG</th>
              <th>FO%</th>
              <th>PP</th>
              <th>PIM</th>
              <th>HITS</th>
              <th>BLKS</th>
              <th>GVA</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='smallLogo'>
                <img
                  src='https://fanserv-media.s3.amazonaws.com/sales_tool/logos/wild.png'
                  alt=''
                />
              </td>
              <td>34</td>
              <td>59%</td>
              <td>3/4</td>
              <td>4</td>
              <td>18</td>
              <td>13</td>
              <td>4</td>
            </tr>
            <tr>
              <td className='smallLogo'>
                <img
                  src={`https://fanserv-media.s3.amazonaws.com/sales_tool/logos/${this.props.away_team_short}.png`}
                />
              </td>
              <td>30</td>
              <td>41%</td>
              <td>1/2</td>
              <td>12</td>
              <td>10</td>
              <td>11</td>
              <td>2</td>
            </tr>
          </tbody>
        </table>
      );
    } else if (this.props.game_condition === 'Home Win') {
      gameStats = (
        <table className='box'>
          <thead>
            <tr>
              <th></th>
              <th>SOG</th>
              <th>FO%</th>
              <th>PP</th>
              <th>PIM</th>
              <th>HITS</th>
              <th>BLKS</th>
              <th>GVA</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='smallLogo'>
                <img
                  src='https://fanserv-media.s3.amazonaws.com/sales_tool/logos/wild.png'
                  alt=''
                />
              </td>
              <td>34</td>
              <td>59%</td>
              <td>3/4</td>
              <td>4</td>
              <td>18</td>
              <td>13</td>
              <td>4</td>
            </tr>
            <tr>
              <td className='smallLogo'>
                <img
                  src={`https://fanserv-media.s3.amazonaws.com/sales_tool/logos/${this.props.away_team_short}.png`}
                />
              </td>
              <td>30</td>
              <td>41%</td>
              <td>1/2</td>
              <td>12</td>
              <td>10</td>
              <td>11</td>
              <td>2</td>
            </tr>
          </tbody>
        </table>
      );
    } else if (this.props.game_condition === 'Home Loss') {
      gameStats = (
        <table className='box'>
          <thead>
            <tr>
              <th></th>
              <th>SOG</th>
              <th>FO%</th>
              <th>PP</th>
              <th>PIM</th>
              <th>HITS</th>
              <th>BLKS</th>
              <th>GVA</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='smallLogo'>
                <img
                  src='https://fanserv-media.s3.amazonaws.com/sales_tool/logos/wild.png'
                  alt=''
                />
              </td>
              <td>35</td>
              <td>46%</td>
              <td>1/3</td>
              <td>2</td>
              <td>20</td>
              <td>11</td>
              <td>2</td>
            </tr>
            <tr>
              <td className='smallLogo'>
                <img
                  src={`https://fanserv-media.s3.amazonaws.com/sales_tool/logos/${this.props.away_team_short}.png`}
                />
              </td>
              <td>26</td>
              <td>54%</td>
              <td>1/1</td>
              <td>6</td>
              <td>15</td>
              <td>14</td>
              <td>3</td>
            </tr>
          </tbody>
        </table>
      );
    } else {
      gameStats = (
        <table className='box'>
          <thead>
            <tr>
              <th></th>
              <th>SOG</th>
              <th>FO%</th>
              <th>PP</th>
              <th>PIM</th>
              <th>HITS</th>
              <th>BLKS</th>
              <th>GVA</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='smallLogo'>
                <img
                  src='https://fanserv-media.s3.amazonaws.com/sales_tool/logos/wild.png'
                  alt=''
                />
              </td>
              <td>25</td>
              <td>53%</td>
              <td>0/1</td>
              <td>2</td>
              <td>7</td>
              <td>14</td>
              <td>15</td>
            </tr>
            <tr>
              <td className='smallLogo'>
                <img
                  src={`https://fanserv-media.s3.amazonaws.com/sales_tool/logos/${this.props.away_team_short}.png`}
                />
              </td>
              <td>25</td>
              <td>53%</td>
              <td>0/1</td>
              <td>2</td>
              <td>7</td>
              <td>14</td>
              <td>15</td>
            </tr>
          </tbody>
        </table>
      );
    }
    // Bottom Box
    let mainBoxScore = null;
    if (this.props.game_state === 'Pregame') {
      mainBoxScore = (
        <div className='mainContentSlider pregame'>
          <div className='frame copyFrame'>{adCopy}</div>
        </div>
      );
    } else {
      mainBoxScore = (
        <div className='mainContentSlider animated'>
          <div className='frame mainBoxScore'>{gameStats}</div>
          <div className='frame copyFrame'>{adCopy}</div>
        </div>
      );
    }

    return (
      <div className='Coca-Cola_300x250-adcontainer'>
        <div className='topBanner'>
          <div className='brandLogo'>
            <img
              src='https://fanserv-media.s3.amazonaws.com/sales_tool/Coca-Cola_logo.svg'
              alt=''
            />
          </div>

          <div className='topBanner-top'>
            <div className='scoreBox'>
              <div className='team team-home'>
                <img
                  src='https://fanserv-media.s3.amazonaws.com/sales_tool/logos/wild.png'
                  alt=''
                />
              </div>
              <div className='clockContainer'>
                {clockScore}
                {clockTime}
              </div>
              <div className='team team-away'>
                <img
                  src={`https://fanserv-media.s3.amazonaws.com/sales_tool/logos/${this.props.away_team_short}.png`}
                />
              </div>
            </div>

            {topBanner}
          </div>
        </div>

        <div className='mainStage'>
          <div className='gameSummary'>Game Summary presented by Coca-Cola</div>
          <div className='mainContent'>{mainBoxScore}</div>
        </div>
      </div>
    );
  }
}

CampaignAlpha_300x250_cocacola.propTypes = {
  game: PropTypes.string,
  game_state: PropTypes.string,
  game_condition: PropTypes.string,
  home_team: PropTypes.string,
  home_team_short: PropTypes.string,
  away_team: PropTypes.string,
  away_team_short: PropTypes.string,
  home_goals: PropTypes.string,
  aways_goals: PropTypes.string,
  clock: PropTypes.string,
  period: PropTypes.string,
};
